@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,700);
*, :before, :after {
  box-sizing: border-box;
}

:active, :focus {
  outline: none;
}

html {
  font-family: sans-serif;
  overflow-x: hidden;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  text-decoration: none;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  vertical-align: middle;
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button {
  cursor: pointer;
}

html input[type="button"] {
  cursor: pointer;
}

input[type="reset"] {
  cursor: pointer;
}

input[type="submit"] {
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  box-sizing: content-box;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

.hide {
  display: none !important;
}

html, body {
  color: #666;
  font-family: Ubuntu, sans-serif;
  font-size: 14px;
}

body {
  background-color: #f6f6f6;
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
  -webkit-user-select: none;
  user-select: none;
}

h1 {
  margin: 25px 0;
  font-size: 24px;
}

h2 {
  margin: 20px 0;
  font-size: 20px;
}

.rcpt-section h2 {
  font-size: 30px;
}

@media (max-width: 980px) {
  .rcpt-section h2 {
    font-size: 22px;
  }
}

h3 {
  font-size: 20px;
}

p {
  line-height: 24px;
}

a {
  transition: color .25s ease-in-out;
}

body {
  color: #000;
  overflow-anchor: none;
  background-color: #f6f6f6;
  min-width: 320px;
  margin: 0;
  padding: 0;
}

body.bg-color-dark {
  background-color: #000;
}

.clear {
  clear: both;
}

.hidden, .hidden-xs {
  visibility: hidden;
  display: none;
}

.visible, .visible-xs {
  visibility: visible;
  display: block;
}

.delimiter {
  clear: both;
  height: 0;
  margin: -1px 0 0;
  overflow: hidden;
}

.cb {
  clear: both;
  height: 1px;
  margin-top: -1px;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.cnc-menu {
  text-align: center;
}

div.cnc-menu nav ul li {
  float: initial;
  display: inline-block;
}

@font-face {
  font-family: Ubuntu;
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu.eot");
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu.woff") format("woff"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Light.eot");
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Light.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Light.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Light.woff") format("woff"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Ubuntu;
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Medium.eot");
  src: url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Medium.eot?#iefix") format("embedded-opentype"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Medium.woff2") format("woff2"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Medium.woff") format("woff"), url("//img2.cncenter.cz/fonts/ubuntu/Ubuntu-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

.btn-main {
  color: #fff;
  background-color: #fbb03b;
  padding: 16px 35px;
  text-decoration: none;
}

.btn-main:hover {
  background-color: #faa41d;
}

.btn-red {
  color: #fff;
  background-color: #ed1c24;
  padding: 20.5px 35px;
  transition: background-color .25s ease-in-out;
}

.btn-red:hover {
  background-color: #dd1219;
}

a[type="button"] {
  color: #fff;
  background-color: #fbb03b;
  border: 0;
  padding: 16px 35px;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
}

input[type="submit"] {
  color: #fff;
  background-color: #fbb03b;
  border: 0;
  padding: 16px 35px;
  text-decoration: none;
  transition: background-color .25s ease-in-out;
}

a[type="button"]:hover {
  background-color: #faa41d;
}

input[type="submit"]:hover {
  background-color: #faa41d;
}

.main-button.load {
  text-align: center;
  color: #fff;
  background: #fbb03b;
  border: 0;
  border-radius: 2px;
  width: 240px;
  padding: 13px 0 12px;
  font-size: 15px;
  font-weight: bold;
  line-height: 15px;
  text-decoration: none;
  transition: background-color .5s;
  display: block;
  position: relative;
}

.main-button.load:disabled {
  color: #666;
  background: #666;
}

.main-button.load:hover:enabled {
  background-color: #faa41d;
  text-decoration: none;
}

.main-button.load.clicked:hover:enabled {
  background-color: #faa41d;
}

.main-button.load:focus {
  outline: 0;
}

.main-button.load > .icon {
  background-position: center;
  width: 100%;
  height: 15px;
  display: inline-block;
}

.main-button.load.icon {
  width: 40px;
}

.button-social {
  width: 78px;
  height: 26px;
  font-size: 12px;
  display: inline-block;
  position: relative;
}

.button-social:hover span {
  text-decoration: underline;
}

.button-social .button-social-icon {
  width: 20px;
  height: 15px;
  position: absolute;
  top: 0;
}

.button-social .button-social-icon div {
  height: 15px;
}

.button-social span {
  color: #f6f6f6;
  vertical-align: middle;
  padding-left: 33px;
  line-height: 26px;
  display: inline-block;
}

@media (max-width: 800px) {
  .button-social span {
    text-align: center;
    width: 100%;
    padding: 0 0 0 10px;
  }
}

.button-facebook {
  background-color: #3b5998;
}

.button-facebook .button-social-icon {
  top: 5px;
  left: 12px;
}

.button-facebook .button-social-icon svg {
  height: 15px;
}

.button-twitter {
  background-color: #50abf1;
}

.button-twitter .button-social-icon {
  top: 5px;
  left: 7px;
}

.button-twitter .button-social-icon svg {
  height: 15px;
}

.basic-form {
  font-size: 17px;
  line-height: 68px;
}

.basic-form input[type="text"] {
  border: 1px solid #b6b6b6;
  outline: none;
  width: 74%;
  height: 68px;
  padding: 0 27px;
}

.basic-form textarea {
  border: 1px solid #b6b6b6;
  outline: none;
  width: 74%;
  height: 68px;
  padding: 0 27px;
  overflow: hidden;
}

.basic-form input[type="submit"] {
  color: #fff;
  float: right;
  background: #fbb03b;
  border: 0;
  outline: none;
  width: 26%;
  height: 68px;
}

.basic-form input[type="submit"]:hover {
  background-color: #fa9e0d;
}

.tabs {
  margin: 0;
  padding: 0;
  position: relative;
}

.tabs input[type="radio"] {
  display: none;
}

.tabs label, .tabs__tab > a {
  color: #fff;
  cursor: pointer;
  background-color: #fbb03b;
  height: 50px;
  padding: 0 30px;
  font-size: 15px;
  line-height: 50px;
  display: block;
}

.tabs label span {
  color: #000;
  display: inline-block;
}

.tabs__tab > a span {
  font-size: 13px;
}

.tabs__tab.active a {
  color: #000;
  cursor: initial;
  background: #fff;
}

.tabs input[type="radio"]:checked + label {
  color: #000;
  cursor: initial;
  background: #fff;
}

.tabs__tab.active a a {
  color: #000;
}

.tabs input[type="radio"]:checked + label a {
  color: #000;
}

.tabs__tab {
  display: inline-block;
}

.tabs__content {
  opacity: 0;
  width: 100%;
  padding: 0;
  position: absolute;
  top: 50px;
  left: 0;
}

.tabs input[type="radio"]:checked + label + .tabs__content {
  opacity: 1;
  z-index: 1;
}

@media screen and (max-width: 750px) {
  .tabs {
    margin: 0;
    padding: 0;
    position: relative;
  }

  .tabs input[type="radio"] {
    display: none;
  }

  .tabs label {
    color: #fff;
    cursor: pointer;
    text-align: center;
    background-color: #fbb03b;
    width: 100%;
    height: 50px;
    padding: 0 30px;
    font-size: 15px;
    line-height: 50px;
    display: block;
  }

  .tabs input[type="radio"]:checked + label {
    color: #ed1c24;
    cursor: initial;
    background: #fff;
  }

  .tabs__tab {
    float: none;
    width: 100%;
    display: block;
  }

  .tabs__content {
    opacity: 1;
    z-index: 1;
    width: 100%;
    height: 0;
    margin-bottom: 5px;
    position: relative;
    top: 0;
    overflow: hidden;
  }

  .tabs input[type="radio"]:checked + label + .tabs__content {
    height: auto;
    margin-bottom: 20px;
  }
}

select {
  height: 35px;
  line-height: 35px;
}

input[type="text"] {
  color: #666;
  background-color: #f6f6f6;
  border: 0;
  height: 35px;
  padding: 10px;
  line-height: 35px;
}

input[type="email"] {
  color: #666;
  background-color: #f6f6f6;
  border: 0;
  height: 35px;
  padding: 10px;
  line-height: 35px;
}

input[type="password"] {
  color: #666;
  background-color: #f6f6f6;
  border: 0;
  height: 35px;
  padding: 10px;
  line-height: 35px;
}

textarea {
  color: #666;
  background-color: #f6f6f6;
  border: 0;
  padding: 10px;
}

input[type="checkbox"] {
  cursor: pointer;
  position: relative;
}

input[type="checkbox"]:before {
  content: "";
  background-color: #fff;
  border: 1px solid #ccc;
  width: 13px;
  height: 13px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

input[type="checkbox"]:checked:before {
  content: "";
  background-color: #fff;
  background-image: url("../img/check-mark-icon.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 90% 90%;
  border: 1px solid #fbb03b;
  width: 13px;
  height: 13px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

header, .branding-wrapper {
  background-color: #f6f6f6;
}

.wrapper {
  text-align: left;
  background-color: #fff;
  width: 1080px;
  margin: 0 auto;
  padding: 0 11px;
}

.wrapper.with-bg {
  background-color: #fff;
}

.wrapper:before, .wrapper:after {
  content: " ";
  display: block;
}

.wrapper:after {
  clear: both;
}

.inner-wrapper {
  clear: both;
  width: 1060px;
  position: relative;
}

.one-col, .two-col, .three-col, .four-col, .five-col, .six-col, .seven-col, .eight-col, .nine-col, .ten-col, .eleven-col, .twelve-col {
  clear: none;
  float: left;
  margin: 0 15px;
  padding: 0;
  display: inline;
  position: relative;
}

.one-col.no-margin, .two-col.no-margin, .three-col.no-margin, .four-col.no-margin, .five-col.no-margin, .six-col.no-margin, .seven-col.no-margin, .eight-col.no-margin, .nine-col.no-margin, .ten-col.no-margin, .eleven-col.no-margin, .twelve-col.no-margin {
  margin: 0;
}

.one-col {
  width: 58px;
}

.two-col {
  width: 146px;
}

.three-col {
  width: 234px;
}

.four-col {
  width: 322px;
}

.five-col {
  width: 410px;
}

.six-col {
  width: 498px;
}

.seven-col {
  width: 586px;
}

.eight-col {
  width: 674px;
}

.nine-col {
  width: 762px;
}

.ten-col {
  width: 850px;
}

.eleven-col {
  width: 938px;
}

.twelve-col {
  width: 1026px;
  margin-right: 0;
}

.prefix-one {
  margin-left: 88px;
}

.prefix-two {
  margin-left: 176px;
}

.prefix-three {
  margin-left: 264px;
}

.prefix-four {
  margin-left: 352px;
}

.prefix-five {
  margin-left: 440px;
}

.prefix-six {
  margin-left: 528px;
}

.prefix-seven {
  margin-left: 606px;
}

.prefix-eight {
  margin-left: 706px;
}

.prefix-nine {
  margin-left: 792px;
}

.prefix-ten {
  margin-left: 880px;
}

.prefix-eleven {
  margin-left: 918px;
}

.last-col {
  margin-right: 0;
}

.m-0 {
  margin: 0;
}

.mt-0 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.m-5 {
  margin: 5px;
}

.mt-5 {
  margin-top: 5px;
}

.mr-5 {
  margin-right: 5px;
}

.mb-5 {
  margin-bottom: 5px;
}

.ml-5 {
  margin-left: 5px;
}

.m-10 {
  margin: 10px;
}

.mt-10 {
  margin-top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.mb-10 {
  margin-bottom: 10px;
}

.ml-10 {
  margin-left: 10px;
}

.m-15 {
  margin: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mr-15 {
  margin-right: 15px;
}

.mb-15 {
  margin-bottom: 15px;
}

.ml-15 {
  margin-left: 15px;
}

.m-20 {
  margin: 20px;
}

.mt-20 {
  margin-top: 20px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

.ml-20 {
  margin-left: 20px;
}

.m-25 {
  margin: 25px;
}

.mt-25 {
  margin-top: 25px;
}

.mr-25 {
  margin-right: 25px;
}

.mb-25 {
  margin-bottom: 25px;
}

.ml-25 {
  margin-left: 25px;
}

.m-30 {
  margin: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-30 {
  margin-right: 30px;
}

.mb-30 {
  margin-bottom: 30px;
}

.ml-30 {
  margin-left: 30px;
}

.m-35 {
  margin: 35px;
}

.mt-35 {
  margin-top: 35px;
}

.mr-35 {
  margin-right: 35px;
}

.mb-35 {
  margin-bottom: 35px;
}

.ml-35 {
  margin-left: 35px;
}

.m-40 {
  margin: 40px;
}

.mt-40 {
  margin-top: 40px;
}

.mr-40 {
  margin-right: 40px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-40 {
  margin-left: 40px;
}

.m-45 {
  margin: 45px;
}

.mt-45 {
  margin-top: 45px;
}

.mr-45 {
  margin-right: 45px;
}

.mb-45 {
  margin-bottom: 45px;
}

.ml-45 {
  margin-left: 45px;
}

.m-50 {
  margin: 50px;
}

.mt-50 {
  margin-top: 50px;
}

.mr-50 {
  margin-right: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.ml-50 {
  margin-left: 50px;
}

.m-55 {
  margin: 55px;
}

.mt-55 {
  margin-top: 55px;
}

.mr-55 {
  margin-right: 55px;
}

.mb-55 {
  margin-bottom: 55px;
}

.ml-55 {
  margin-left: 55px;
}

.m-60 {
  margin: 60px;
}

.mt-60 {
  margin-top: 60px;
}

.mr-60 {
  margin-right: 60px;
}

.mb-60 {
  margin-bottom: 60px;
}

.ml-60 {
  margin-left: 60px;
}

.m-65 {
  margin: 65px;
}

.mt-65 {
  margin-top: 65px;
}

.mr-65 {
  margin-right: 65px;
}

.mb-65 {
  margin-bottom: 65px;
}

.ml-65 {
  margin-left: 65px;
}

.m-70 {
  margin: 70px;
}

.mt-70 {
  margin-top: 70px;
}

.mr-70 {
  margin-right: 70px;
}

.mb-70 {
  margin-bottom: 70px;
}

.ml-70 {
  margin-left: 70px;
}

.m-75 {
  margin: 75px;
}

.mt-75 {
  margin-top: 75px;
}

.mr-75 {
  margin-right: 75px;
}

.mb-75 {
  margin-bottom: 75px;
}

.ml-75 {
  margin-left: 75px;
}

.m-80 {
  margin: 80px;
}

.mt-80 {
  margin-top: 80px;
}

.mr-80 {
  margin-right: 80px;
}

.mb-80 {
  margin-bottom: 80px;
}

.ml-80 {
  margin-left: 80px;
}

.m-85 {
  margin: 85px;
}

.mt-85 {
  margin-top: 85px;
}

.mr-85 {
  margin-right: 85px;
}

.mb-85 {
  margin-bottom: 85px;
}

.ml-85 {
  margin-left: 85px;
}

.m-90 {
  margin: 90px;
}

.mt-90 {
  margin-top: 90px;
}

.mr-90 {
  margin-right: 90px;
}

.mb-90 {
  margin-bottom: 90px;
}

.ml-90 {
  margin-left: 90px;
}

.m-95 {
  margin: 95px;
}

.mt-95 {
  margin-top: 95px;
}

.mr-95 {
  margin-right: 95px;
}

.mb-95 {
  margin-bottom: 95px;
}

.ml-95 {
  margin-left: 95px;
}

.m-100 {
  margin: 100px;
}

.mt-100 {
  margin-top: 100px;
}

.mr-100 {
  margin-right: 100px;
}

.mb-100 {
  margin-bottom: 100px;
}

.ml-100 {
  margin-left: 100px;
}

.ads.skyscaper {
  margin-top: 35px;
}

.gam-wrapper {
  clear: both;
  display: block;
}

.gam-wrapper--padding {
  padding: 20px 0;
}

.sticky-wrapper {
  min-height: 600px;
  margin-bottom: 20px;
  position: relative;
}

.sticky-wrapper .sticky-content {
  min-width: 322px;
}

.sticky-wrapper .fixed {
  position: fixed;
  top: 10px;
}

.sticky-wrapper .sticky-content.polyfill-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 10px;
}

.ad-skyscrapper {
  padding: 0 15px;
}

.mimibazar-block.customizable {
  border-top: 0;
  margin: 0 0 10px;
}

.mimibazar-block.customizable h2 {
  color: #000;
  text-transform: none;
  margin: 0 0 20px;
  font-family: Ubuntu, sans-serif;
  font-size: 20px;
}

.mimibazar-block.customizable h2 a {
  color: #000;
}

.mimibazar-block.customizable .item .image {
  background: #ccc;
}

.mimibazar-block.customizable .item .title {
  color: #fbb03b;
}

.mimibazar-block.customizable .item .price {
  color: #ccc;
}

.mimibazar-block.customizable .item .more {
  color: #ed1c24;
  border-bottom-color: #ed1c24;
}

.mimibazar-block.customizable .item .more:after, .mimibazar-block.customizable .item .more:before {
  background-color: #ed1c24;
}

.mimibazar-block.customizable .item .more:hover {
  color: #ccc;
}

.mimibazar-block.customizable .item .more:hover:after, .mimibazar-block.customizable .item .more:hover:before {
  background-color: #ccc;
}

.rcp.mimibazar-block.customizable h2 {
  margin-bottom: 15px;
}

.rcp.mimibazar-block.customizable .items .item .title, .rcp.mimibazar-block.customizable .items .item .price, .rcp.mimibazar-block.customizable .items .item .more {
  font-size: 16px;
}

.page-wrapper {
  max-width: 1080px;
  margin: 0 auto;
}

@media (max-width: 1081px) {
  .page-wrapper {
    padding: 0 25px;
  }
}

@media (max-width: 500px) {
  .page-wrapper {
    margin: 0;
    padding: 0;
  }
}

.rcpt-section h2 {
  margin-top: 50px;
  margin-bottom: 40px;
}

.horizontal-ad div {
  background-color: #ddd;
  width: 720px;
  height: 150px;
  margin: 45px auto 0;
}

@media (min-width: 1082px) {
  .horizontal-ad {
    display: none;
  }
}

@media (max-width: 785px) {
  .horizontal-ad {
    display: none;
  }
}

.content-sidebar {
  float: left;
  width: 740px;
}

@media (max-width: 1081px) {
  .content-sidebar {
    width: 100%;
  }
}

.sidebar {
  float: right;
  width: 300px;
}

.sidebar__item {
  margin-bottom: 20px;
}

.sidebar__item:last-child {
  margin-bottom: 0;
}

.sidebar__fix-ad {
  position: relative;
}

.sidebar__fix-ad .skyscaper {
  will-change: scroll-position;
  background-color: #ddd;
  width: 300px;
  height: 600px;
}

.sidebar__fix-ad .skyscaper.fixed {
  position: fixed;
  top: 0;
}

.sidebar__fix-ad .skyscaper.absolute {
  top: inherit;
  position: absolute;
  bottom: 0;
}

@media (max-width: 1081px) {
  .sidebar {
    display: none;
  }
}

.clearfix:before, .clearfix:after {
  content: " ";
  display: block;
}

.clearfix:after {
  clear: both;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
}

@media (max-width: 750px) {
  .row {
    flex-wrap: wrap;
  }
}

.column-6 {
  float: left;
  width: 50%;
  margin: 0 15px;
}

@media (max-width: 750px) {
  .column-6 {
    width: 100%;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.owl-height {
  transition: height .5s ease-in-out;
}

.owl-carousel {
  tap-highlight-color: transparent;
  z-index: 1;
  width: 100%;
  display: none;
  position: relative;
}

.owl-carousel .animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
}

.owl-carousel .owl-stage:after {
  content: ".";
  clear: both;
  visibility: hidden;
  height: 0;
  line-height: 0;
  display: block;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-controls .owl-nav .owl-prev, .owl-carousel .owl-controls .owl-nav .owl-next, .owl-carousel .owl-controls .owl-dot {
  cursor: hand;
  -webkit-user-select: none;
  user-select: none;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel .owl-refresh .owl-item {
  display: none;
}

.owl-carousel .owl-item {
  float: left;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  tap-highlight-color: transparent;
  -webkit-user-select: none;
  user-select: none;
  min-height: 1px;
  position: relative;
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity .4s;
}

.owl-carousel .owl-item img {
  transform-style: preserve-3d;
  width: 100%;
  display: block;
}

.owl-carousel.owl-text-select-on .owl-item {
  -webkit-user-select: auto;
  user-select: auto;
}

.owl-carousel .owl-grab {
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

.owl-carousel .owl-video-wrapper {
  background: #000;
  height: 100%;
  position: relative;
}

.owl-carousel .owl-video-play-icon {
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background: url("owl.video.play.png") no-repeat;
  width: 80px;
  height: 80px;
  margin-top: -40px;
  margin-left: -40px;
  transition: scale .1s;
  position: absolute;
  top: 50%;
  left: 50%;
}

.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn, .owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  transition: opacity .4s;
}

.owl-carousel .owl-video-frame {
  z-index: 1;
  position: relative;
}

.no-js .owl-carousel {
  display: block;
}

.gallery-main-container, .gallery-vertical-container {
  background-color: #000;
  width: 100%;
  height: 100%;
  position: relative;
}

.gallery-main-container:before, .gallery-main-container:after, .gallery-vertical-container:before, .gallery-vertical-container:after {
  content: " ";
  display: block;
}

.gallery-main-container:after, .gallery-vertical-container:after {
  clear: both;
}

.gallery-main-container .gallery, .gallery-vertical-container .gallery {
  width: 100%;
  height: 91%;
}

.gallery-main-container .gallery .icon-loading, .gallery-vertical-container .gallery .icon-loading {
  width: 35px;
  height: 35px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container, .gallery-vertical-container .gallery .images-container {
  width: 100%;
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item, .gallery-vertical-container .gallery .images-container .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .images-container .owl-item img, .gallery-vertical-container .gallery .images-container .owl-item img {
  width: auto;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .images-container .owl-item.advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.advertisement:after {
  content: "REKLAMA";
  color: #000;
  text-align: center;
  opacity: 0;
  z-index: -1;
  background: rgba(255, 255, 255, .3);
  width: 130px;
  height: 37px;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  transition: opacity .2s;
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 65px);
}

.gallery-main-container .gallery .images-container .owl-item.active > .advertisement:after, .gallery-vertical-container .gallery .images-container .owl-item.active > .advertisement:after {
  opacity: 1;
}

.gallery-main-container .gallery .images-container .owl-stage-outer, .gallery-vertical-container .gallery .images-container .owl-stage-outer, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage, .gallery-main-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item, .gallery-vertical-container .gallery .images-container .owl-stage-outer .owl-stage .owl-item {
  height: 100%;
}

.gallery-main-container .gallery .btn, .gallery-vertical-container .gallery .btn {
  z-index: 10;
  cursor: pointer;
  background: rgba(0, 0, 0, .6);
  width: 9%;
  height: 91%;
  display: block;
  position: absolute;
}

.gallery-main-container .gallery .btn .icon-arrow, .gallery-vertical-container .gallery .btn .icon-arrow {
  width: 13px;
  height: 26px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .next, .gallery-vertical-container .gallery .next {
  right: 0;
}

.gallery-main-container .gallery .next .image-count, .gallery-vertical-container .gallery .next .image-count {
  text-align: center;
  color: #fff;
  margin: auto;
  font-size: 26px;
  font-weight: 300;
  position: absolute;
  bottom: 5px;
  left: 4%;
  right: 4%;
}

.gallery-main-container .image-description, .gallery-vertical-container .image-description {
  color: #aaa;
  padding: 20px 0;
}

.gallery-main-container .image-description div, .gallery-vertical-container .image-description div {
  display: inline-block;
}

.gallery-main-container .image-description .description, .gallery-vertical-container .image-description .description {
  padding: 0 5px 0 0;
}

.gallery-main-container .image-description .author, .gallery-vertical-container .image-description .author {
  font-style: italic;
}

.gallery-container.vertical .gallery-vertical-container {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container img[data-src^="http"], .gallery-container.vertical .gallery-vertical-container img[data-src^="http"] + .image-description {
  display: none;
}

.gallery-container.vertical .gallery-vertical-container img {
  margin: 10px 0 0;
}

.gallery-container.vertical .gallery-vertical-container .load-button {
  letter-spacing: 1px;
  margin: 20px auto;
  padding: 10px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description {
  padding: 20px 10px;
}

.gallery-container.vertical .gallery-vertical-container .image-description .image-description {
  clear: both;
  width: 100%;
  padding: 15px 0;
}

.gallery-container.vertical .gallery-vertical-container .image-description .author {
  float: left;
  display: block;
}

.gallery-container.vertical .gallery-vertical-container .image-description .photo-count {
  float: right;
  color: #fff;
  font-weight: 700;
}

.gallery-container.vertical .gallery-vertical-container .gallery {
  height: auto;
}

.gallery-container.vertical .gallery-vertical-container .images-container {
  margin-top: 35px;
}

.owl-nav.disabled {
  display: none;
}

.gallery-with-video .gallery-item .video-item {
  width: 81%;
  margin: auto;
}

.gallery-container {
  background-color: #000;
  height: 100%;
  margin-top: 60px;
}

.gallery-container .gallery-container {
  width: 1080px;
  height: 693px;
  margin: auto auto 60px;
}

.gallery-container .gallery-container .gallery-main-container div.image-description {
  background-color: #000;
  padding: 10px;
}

.gallery-container .gallery-container .gallery {
  border: 1px solid #000;
  height: 693px;
  position: relative;
}

.gallery-container .gallery-container .gallery .btn {
  background-color: #000;
  width: 90px;
  height: auto;
  top: 0;
  bottom: 1px;
}

.gallery-container .gallery-container .gallery .images-container {
  height: 690px;
}

.gallery-container .gallery-container .image-description {
  display: block;
}

.gallery-container .gallery-advertisement {
  text-align: center;
  margin: 5px 0;
}

body.bg-color-dark .gallery-main-container {
  height: 693px;
}

.gallery-main-container {
  background-color: #000;
  height: 397px;
}

.gallery-main-container .gallery {
  height: 100%;
}

.gallery-main-container .gallery .btn {
  background-color: rgba(100, 100, 100, .3);
  width: 50px;
  height: 100%;
}

.gallery-main-container .gallery .btn .icon-arrow {
  width: 40px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 4%;
  right: 4%;
}

.gallery-main-container .gallery .btn .icon-arrow.dataimg-arrow-light-right {
  background: url("https://img2.cncenter.cz/images/receptycz/dist/svg/arrow-light-right.svg") no-repeat;
}

.gallery-main-container .gallery .btn .icon-arrow.dataimg-arrow-light-left {
  background: url("https://img2.cncenter.cz/images/receptycz/dist/svg/arrow-light-left.svg") no-repeat;
}

.gallery-main-container .gallery .next .image-count {
  opacity: 1;
  font-size: 15px;
  bottom: 15px;
}

.gallery-main-container .gallery .images-container {
  height: 397px;
}

.gallery-main-container .gallery .images-container .owl-item img {
  left: 0;
  right: 0;
}

.gallery-main-container .image-description {
  display: none;
}

@media screen and (max-width: 1900px) {
  .gallery-container .gallery-container {
    width: 100%;
    height: 350px;
    margin-bottom: 60px;
  }

  .gallery-container .image-description {
    padding: 15px;
  }

  .gallery-main-container .gallery .btn {
    width: 50px;
  }

  .gallery-main-container .gallery .next .image-count {
    font-size: .8em;
  }
}

.error-404, .error-410 {
  background: url("../../../images/receptycz/dist/non-sprite/404.png");
  height: 688px;
  margin-bottom: 20px;
  position: relative;
}

.error-404 .error-text, .error-410 .error-text {
  color: #fff;
  text-align: center;
  width: 100%;
  font-size: 20px;
  line-height: 32px;
  position: absolute;
  bottom: 80px;
  left: 0;
}

.error-404 .error-text .text-404, .error-404 .error-text .text-410, .error-410 .error-text .text-404, .error-410 .error-text .text-410 {
  font-size: 190px;
  font-weight: bold;
  line-height: 213px;
  display: block;
}

.error-404 .error-text .error-text-bottom, .error-410 .error-text .error-text-bottom {
  margin-top: 10px;
  font-weight: 500;
}

.error-404 .error-text a, .error-410 .error-text a {
  color: #fbb03b;
  text-decoration: underline;
}

.error-404 .error-text a:hover, .error-410 .error-text a:hover {
  text-decoration: none;
}

.main-article h1.title {
  color: #000;
  margin: 15px 0;
  line-height: 31px;
  display: block;
}

.main-article h1.title:before, .main-article h1.title:after {
  content: " ";
  display: block;
}

.main-article h1.title:after {
  clear: both;
}

.main-article h2 {
  color: #000;
  margin-bottom: 0;
  padding-left: 60px;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
}

.main-article h2 a {
  color: #fbb03b;
  text-decoration: underline;
  transition: all .25s ease-in-out;
}

.main-article h2 a:hover {
  color: #ed1c24;
  text-decoration: none;
}

.main-article h3 {
  color: #000;
  list-style: 22px;
  margin: 0;
  padding: 15px 0 0 60px;
  font-size: 17px;
  font-weight: bold;
  display: inline-block;
}

.main-article h3 a {
  color: #fbb03b;
  text-decoration: underline;
  transition: all .25s ease-in-out;
}

.main-article h3 a:hover {
  color: #ed1c24;
  text-decoration: none;
}

.main-article h3 article {
  position: relative;
  left: -60px;
}

.main-article h3 .title-content {
  float: right;
  text-align: left;
  width: calc(100% - 342px);
}

.main-article .ads table {
  border-top: 0;
  margin-bottom: 0;
}

.main-article .image-container {
  padding-bottom: 15px;
}

.main-article .image-container .height-auto {
  height: auto;
}

.main-article .image-container .description {
  text-align: left;
  width: 100%;
  padding-top: 15px;
  display: block;
}

.main-article ul {
  margin-top: 0;
  margin-left: 60px;
  padding: 0;
  list-style: none;
}

.main-article ul li {
  padding-left: 17px;
  line-height: 26px;
}

.main-article ul li:before {
  content: "•";
  color: #000;
  margin-left: -17px;
  font-weight: 600;
  line-height: 26px;
  position: absolute;
}

.main-article ul.main {
  margin: 0;
}

.main-article ul.main > li {
  padding: 0;
}

.main-article ul.main > li:before {
  display: none;
}

.main-article .listing-controls {
  text-align: center;
}

.main-article .listing-controls button {
  display: none;
}

.main-article .listing-controls button.icon, .main-article .listing-controls button.start {
  float: right;
  margin-left: 5px;
}

.main-article .silhouette-image div {
  width: 690px;
  height: 408px;
}

.main-article .content p {
  color: #000;
  padding-left: 60px;
  font-size: 15px;
  line-height: 26px;
}

.main-article .content p:empty {
  display: none;
}

.main-article .content p strong {
  color: #000;
}

.main-article .content p iframe {
  margin: 15px auto 15px 0;
}

.main-article .content p a {
  color: #fbb03b;
  text-decoration: underline;
  transition: all .25s ease-in-out;
}

.main-article .content p a:hover {
  color: #fbb03b;
  text-decoration: none;
}

.main-article .content p a strong {
  color: #fbb03b;
}

.main-article .content .twitter-tweet {
  margin-left: 60px;
}

.main-article .content h2 {
  padding: 15px 0 0 60px;
}

.main-article .content table p {
  padding-left: 0;
}

.main-article .content .article-link {
  margin: 15px 0;
}

.main-article .content .article-link .image-container {
  width: 322px;
}

.main-article .content .img-wrap {
  text-align: center;
}

.main-article .content .kw-in-art {
  background-color: #ccc;
  margin-left: 60px;
  padding: 15px 10px;
  display: flex;
}

.main-article .content .kw-in-art .kw-logo {
  width: 50px;
}

.main-article .content .kw-in-art .kw-logo .icon {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  display: block;
}

.main-article .content .kw-in-art .kw-logo .icon svg path {
  fill: #000;
}

.main-article .content .kw-in-art .kw-content {
  border-left: 1px solid #666;
  padding-left: 20px;
}

.main-article .content .kw-in-art .kw-content strong {
  text-transform: uppercase;
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

.main-article .content .kw-in-art .kw-content a {
  letter-spacing: .5px;
  color: #f6f6f6;
  background-color: #fbb03b;
  border-radius: 9px;
  height: 18px;
  margin: 12px 10px 0 0;
  padding: 0 9px;
  font-size: 11px;
  font-weight: normal;
  line-height: 18px;
  display: inline-block;
}

.main-article .content .kw-in-art .kw-content a:first-letter {
  text-transform: uppercase;
}

.main-article .article-comments {
  margin: 0;
  padding: 15px 0;
}

.main-article .related-articles {
  margin: 15px 0;
}

.main-article .related-articles .title-container .title {
  color: #000;
  padding-left: 0;
  font-weight: bold;
}

.main-article .shares-and-date {
  clear: both;
  padding: 15px 0;
  overflow: auto;
}

.main-article .shares-and-date .share-buttons {
  float: left;
}

.main-article .shares-and-date .share-buttons .button-twitter {
  margin-left: 20px;
}

.main-article .shares-and-date .date, .main-article .shares-and-date .commercial-label {
  float: right;
  color: #666;
  line-height: 26px;
}

.main-article p {
  margin: 0;
  padding: 15px 0;
}

.main-article .meta-container {
  background: #bababa;
  width: 1081px;
  position: relative;
}

.main-article .meta-container:before, .main-article .meta-container:after {
  content: " ";
  display: block;
}

.main-article .meta-container:after {
  clear: both;
}

.main-article .meta-container .author-container {
  float: left;
  width: 100px;
}

.main-article .meta-container .author-container img.author-photo {
  border: 0;
  max-width: 80px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.main-article .meta-container .meta {
  float: left;
  margin: 20px 0 15px 100px;
}

.main-article .meta-container .meta .category-name {
  float: left;
  text-transform: uppercase;
  margin-right: 30px;
  font-weight: bold;
  line-height: 20px;
}

.main-article .meta-container .meta .datetime {
  float: left;
  color: #666;
  line-height: 20px;
}

.main-article .meta-container .meta .author-name-container {
  float: left;
  color: #666;
  font-weight: bold;
}

.main-article .meta-container .meta .author-name-container a {
  color: #666;
}

.main-article .meta-container .image-container:before, .main-article .meta-container .image-container:after {
  content: " ";
  display: block;
}

.main-article .meta-container .image-container:after {
  clear: both;
}

.main-article .article-gallery-container {
  background-color: #bababa;
  padding: 20px;
}

.main-article .gallery h2 {
  color: #000;
  padding: 15px 0 5px;
  font-size: 17px;
  font-weight: normal;
}

.main-article .gallery .article-gallery-strip {
  margin-bottom: 20px;
}

.main-article .perex {
  margin: 0;
  padding: 0 0 0 60px;
  font-size: 17px;
  font-weight: bold;
  line-height: 26px;
}

.main-article .perex p {
  color: #000;
  margin: 0;
  line-height: 26px;
}

.main-article figure {
  text-align: right;
  margin: 15px 0;
}

.main-article figure .image-in-article img {
  width: 100%;
  max-width: 614px;
  height: auto;
  padding: 15px 0 0;
}

.main-article figure .image-in-article .description {
  color: #666;
  text-align: left;
  float: right;
  width: 100%;
  max-width: 614px;
  padding-top: 15px;
  display: block;
}

.main-article .image-in-article figure .description {
  color: #666;
  float: right;
  width: 100%;
  max-width: 614px;
  padding-top: 15px;
  display: block;
}

.main-article .related-articles-links {
  margin-top: 15px;
}

.main-article .authors-simple {
  padding: 15px 0;
}

.main-article.notlisted .bottom {
  display: none;
}

.main-article.listed ul.main > li {
  display: none;
  overflow: hidden;
}

.main-article.listed ul.main > li.active {
  display: block;
}

.main-article.listed .art-section-top {
  border-top: 1px solid #e9ebee;
  padding-top: 20px;
  padding-bottom: 10px;
}

.main-article.listed .art-section-top h2 {
  width: calc(100% - 100px);
  margin-top: 0;
  padding-top: 10px;
}

.main-article.listed .sections-paging {
  position: relative;
}

.main-article.listed .sections-paging button {
  line-height: 15px;
}

.main-article.listed .sections-paging .start-listing {
  text-align: center;
  margin: 15px auto;
}

.main-article.listed .sections-paging .show-start {
  width: auto;
  padding: 13px 12px 12px;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next, .main-article.listed .sections-paging .prev {
  width: 40px;
  height: 40px;
  padding: 0;
  position: absolute;
  top: 1px;
  right: 0;
}

.main-article.listed .sections-paging .next span, .main-article.listed .sections-paging .prev span {
  background-position: center;
  width: 15px;
  height: 25px;
  display: inline-block;
}

.main-article.listed .sections-paging .prev {
  right: 55px;
}

.author-articles {
  border-top: 1px solid #666;
  border-bottom: 1px solid #666;
  margin: 15px 0;
  padding: 25px 0 15px;
}

.author-articles.not-last {
  border-bottom: 0;
}

.author-articles:after {
  content: "";
  clear: both;
  height: 1px;
  margin-top: -1px;
  display: block;
  overflow: hidden;
}

.author-articles .author-image {
  text-align: center;
  float: left;
  width: 240px;
}

.author-articles .author-image > a {
  border: 1px solid #666;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

.author-articles .author-image > a img {
  width: 100%;
  height: auto;
}

.author-articles .author-image h3 {
  text-align: center;
  padding-top: 20px;
  padding-left: 0;
  font-weight: bold;
}

.author-articles .author-image h3 a {
  color: #000;
  text-decoration: none;
}

.author-articles .author-image h3 a:hover {
  color: #ed1c24;
}

.author-articles .author-article-list {
  float: left;
  width: 430px;
}

.author-articles .author-article-list .category-name {
  vertical-align: middle;
  display: inline-block;
}

.author-articles .author-article-list .category-name a {
  color: #666;
  margin: 0 0 10px 30px;
  font-size: 13px;
  text-decoration: none;
  display: inline-block;
}

.author-articles .author-article-list .category-name a:hover {
  text-decoration: underline;
}

.author-articles .author-article-list .list-article-line {
  width: 100%;
  max-width: none;
  margin: 5px 0;
}

.author-articles .author-article-list .list-article-line .title-container {
  float: left;
}

.author-articles .author-article-list .list-article-line .title-container h2 {
  width: 100%;
  padding: 0;
}

.article-gallery-strip {
  margin: 10px 0 15px;
}

.article-gallery-strip > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article-gallery-strip > ul > li {
  float: left;
  margin: 0 5px;
  padding: 0;
}

.article-gallery-strip > ul > li:before {
  display: none;
}

.article-gallery-strip > ul > li > a {
  display: block;
}

.article-gallery-strip > ul > li:first-child {
  margin: 0 7px;
}

.article-gallery-strip > ul > li.photos-left {
  color: #fbb03b;
  margin: 0 0 0 5px;
  font-size: 13px;
  font-weight: bold;
  line-height: 26px;
  position: relative;
  top: 15px;
}

.article-gallery-strip > ul > li.photos-left .photos-count {
  text-align: center;
  font-size: 27px;
}

.article-gallery-strip > ul > li.photos-left .photos-count-desc {
  font-size: 15px;
  font-weight: normal;
}

.article-gallery-strip > ul > li.photos-left > a {
  color: #fbb03b;
  text-decoration: none;
  transition: color .25s ease-in-out;
  display: block;
}

.article-gallery-strip > ul > li.photos-left > a:hover {
  color: #ed1c24;
  text-decoration: none;
}

.article-gallery-strip > ul > li.photos-left > a:hover .photos-count-desc {
  text-decoration: underline;
}

.article-keywords {
  margin: 15px 0;
  position: relative;
}

.article-keywords > .title {
  color: #000;
  float: left;
  width: 60px;
  margin: 0;
  font-size: 15px;
  font-weight: normal;
  line-height: 24px;
  display: block;
}

.article-keywords .keywords {
  margin-left: 10px;
  font-size: 15px;
}

.article-keywords .keywords > a {
  color: #fbb03b;
  line-height: 24px;
  transition: all .25s ease-in-out;
  display: inline-block;
}

.article-keywords .keywords > a:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.article-keywords .divider {
  color: #fbb03b;
}

.breadcrumbs {
  margin: 10px 0;
}

.breadcrumbs ol {
  margin: 0;
  padding: 0;
}

.breadcrumbs ol li {
  color: #fbb03b;
  margin-right: 15px;
  font-weight: normal;
  line-height: 20px;
  list-style-type: none;
  display: inline-block;
}

.breadcrumbs ol li a {
  color: #fbb03b;
  margin-right: 15px;
  font-weight: normal;
  text-decoration: underline;
}

.breadcrumbs ol li a:hover {
  text-decoration: none;
}

.breadcrumbs ol li:last-of-type:after {
  content: "";
}

.breadcrumbs ol li:after {
  content: ":";
  color: #ccc;
}

.breadcrumbs ol .dataimg-arrow-right {
  display: none;
}

.header-dark {
  z-index: 100;
  background-color: #fbb03b;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
}

.header-dark:before, .header-dark:after {
  content: " ";
  display: block;
}

.header-dark:after {
  clear: both;
}

.header-dark .header-container {
  width: 1080px;
  margin: auto;
}

.header-dark .header-container a {
  text-decoration: none;
}

.header-dark .header-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .logo {
  float: left;
  background-position: center;
  width: 150px;
  height: auto;
  margin: 5px 15px 0 0;
}

.header-dark .header-container .gallery-close {
  color: #fff;
  float: right;
  margin: 20px 0;
  font-size: 15px;
  font-weight: bold;
  display: block;
  position: relative;
}

.header-dark .header-container .gallery-close span {
  text-transform: uppercase;
  position: relative;
  top: 5px;
}

.header-dark .header-container .gallery-close .icon {
  float: right;
  width: 25px;
  height: 25px;
  margin: 0 0 0 10px;
}

.header-dark .header-container .gallery-close:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container {
  float: left;
  padding: 8px 20px 0;
  font-weight: bold;
}

.header-dark .header-container .titles-container a {
  text-decoration: none;
}

.header-dark .header-container .titles-container a:hover {
  text-decoration: underline;
}

.header-dark .header-container .titles-container .title {
  color: #000;
  float: left;
  text-transform: uppercase;
  margin: 3px 0;
  font-size: 13px;
}

.header-dark .header-container .titles-container .article-title {
  color: #fff;
  float: left;
  clear: both;
  margin-top: 2px;
  font-size: 15px;
}

.header-search.homepage {
  text-align: center;
  width: 100%;
  position: absolute;
  top: 205px;
}

.header-search.homepage:before, .header-search.homepage:after {
  content: " ";
  display: block;
}

.header-search.homepage:after {
  clear: both;
}

.header-search.other {
  background-image: url("../../../../images/receptycz/dist/non-sprite/header-background-other.jpg?ver=3");
  background-size: initial;
  background-color: #373a3f;
  background-position: center;
  height: 180px;
  padding: 30px 25px 0;
}

.header-search.other:before, .header-search.other:after {
  content: " ";
  display: block;
}

.header-search.other:after {
  clear: both;
}

.header-search.other .header-search__form {
  float: left;
  width: calc(100% - 550px);
  margin: 10px 0 5px 60px;
}

@media (max-width: 1081px) {
  .header-search.other .header-search__form {
    margin: 10px 0 25px 20px;
  }
}

.header-search__wrapper {
  max-width: 1081px;
  margin: 0 auto;
  position: relative;
}

.header-search__wrapper:before, .header-search__wrapper:after {
  content: " ";
  display: block;
}

.header-search__wrapper:after {
  clear: both;
}

.header-search h1, .header-search__wrapper__span {
  float: left;
  width: 30%;
  margin: 0;
}

.header-search h1 img, .header-search__wrapper__span img {
  width: 100%;
  max-width: 305px;
}

.header-search__image {
  float: left;
  width: 215px;
  margin-left: 32px;
}

.header-search span#form-main-search-form-text-message, .header-search span#form-main-search-form-send-message {
  float: left;
  width: 324px;
}

.header-search span#form-main-search-form-text-message img, .header-search span#form-main-search-form-send-message img {
  width: 100%;
  max-width: 305px;
}

.header-search__form {
  max-width: 750px;
  margin: 0 auto;
  font-size: 17px;
  line-height: 40px;
  position: relative;
}

.header-search__form:before, .header-search__form:after {
  content: " ";
  display: block;
}

.header-search__form:after {
  clear: both;
}

.header-search__form input[type="radio"] {
  display: none;
}

.header-search__form input[type="text"] {
  background-color: #fff;
  border: 1px solid #fbb03b;
  outline: none;
  width: calc(100% - 150px);
  height: 50px;
  padding: 0 27px;
}

.header-search__form input[type="email"] {
  background-color: #fff;
  border: 1px solid #fbb03b;
  outline: none;
  width: calc(100% - 150px);
  height: 50px;
  padding: 0 27px;
}

.header-search__form button[type="submit"] {
  color: #fff;
  float: right;
  background: #fbb03b;
  border: 0;
  outline: none;
  width: 150px;
  height: 50px;
  font-size: 17px;
  transition: background-color .25s ease-in-out;
}

.header-search__form input[type="submit"] {
  color: #fff;
  float: right;
  background: #fbb03b;
  border: 0;
  outline: none;
  width: 150px;
  height: 50px;
  font-size: 17px;
  transition: background-color .25s ease-in-out;
}

.header-search__form button[type="submit"]:hover {
  background-color: #fa9e0d;
}

.header-search__form input[type="submit"]:hover {
  background-color: #fa9e0d;
}

.header-search__form button[type="submit"] img {
  height: 28px;
  margin-top: -5px;
  padding-right: 15px;
}

.header-search__form input[type="submit"] img {
  height: 28px;
  margin-top: -5px;
  padding-right: 15px;
}

.header-search__form .help-block {
  display: none;
}

.header-search__form div span#form-main-search-form-text-message, .header-search__form div span#form-main-search-form-send-message {
  font-size: 15px;
  line-height: 15px;
  position: absolute;
  top: -17px;
}

@media (max-width: 767px) {
  .header-search__form div span {
    top: -17px;
  }
}

.header-search__options {
  position: absolute;
  top: -25px;
}

.header-search__options label {
  color: #fff;
  float: left;
  text-align: center;
  letter-spacing: .2px;
  cursor: pointer;
  background: #000;
  padding: 0 11px;
  font-size: 14px;
  line-height: 25px;
  display: block;
}

@media (max-width: 1080px) {
  .header-search__options label {
    display: none;
  }
}

.header-search__options label.selected {
  color: #fbb03b;
  cursor: default;
  background: linear-gradient(0deg, #f5f5f5 0%, #fff 100%) no-repeat padding-box padding-box;
  border-top: 1px solid #fbb03b;
  border-left: 1px solid #fbb03b;
  border-right: 1px solid #fbb03b;
}

.header-search__add-recipe {
  text-align: center;
  float: left;
  width: 187px;
  height: 50px;
  margin-top: 10px;
  margin-left: 10px;
  line-height: 50px;
  position: relative;
}

.header-search__add-recipe a {
  height: 100%;
  padding: 0;
  font-size: 17px;
  display: block;
}

.header-search__add-recipe a img {
  margin-top: -5px;
  margin-right: 12px;
}

.header-search__quick-links {
  text-align: right;
  font-size: 12px;
  line-height: 30px;
}

.header-search__quick-links a {
  color: #fbb03b;
  margin-right: 6px;
  font-size: 14px;
  text-decoration: underline;
}

.header-search__quick-links a:last-child {
  margin-right: 0;
}

.header-search__quick-links a:hover {
  text-decoration: none;
}

@media (max-width: 1081px) {
  .header-search__quick-links {
    display: none;
  }
}

.header-search__links {
  clear: both;
  padding-top: 13px;
  position: relative;
}

@media (max-width: 767px) {
  .header-search__links {
    background-color: #fff;
    padding: 15px 10px;
  }
}

.header-search__links--wrapper {
  text-align: center;
  height: 17px;
  overflow: hidden;
}

.header-search__links--wrapper a {
  color: #fbb03b;
  margin: 0 5px;
  font-size: 14px;
  text-decoration: underline;
  display: inline-block;
}

.header-search__links--wrapper a:last-child {
  margin-right: 0;
}

.header-search__links--wrapper a:hover {
  text-decoration: none;
}

@media (max-width: 1080px) {
  .header-search__links--wrapper a {
    font-size: 15px;
  }

  .header-search__links--wrapper {
    height: auto;
    line-height: 30px;
  }
}

.header-search__links-more {
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  right: -43px;
}

.header-search__links-more:after {
  content: "";
  cursor: pointer;
  background-image: url("//img2.cncenter.cz/images/receptycz/dist/non-sprite/dots.svg");
  background-size: 100% 100%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 8px;
  right: 0;
  transform: rotate(90deg);
}

.header-search__links-more.expanded:after {
  background-image: url("//img2.cncenter.cz/images/receptycz/dist/non-sprite/add.svg");
  transform: rotate(45deg);
}

@media (min-width: 1081px) {
  .header-search__links-more {
    display: none;
  }
}

@media (max-width: 1101px) {
  .header-search__links a:nth-last-child(2) {
    display: none;
  }
}

@media (max-width: 1080px) {
  .header-search.other {
    background-repeat: no-repeat;
    background-size: 140% 100%;
    height: 150px;
    padding: 48px 25px 0;
  }
}

@media (max-width: 1080px) and (max-width: 1080px) {
  .header-search.other {
    height: auto;
  }
}

@media (max-width: 1080px) {
  .header-search h1 {
    width: auto;
    margin: 0;
  }

  .header-search h1 img {
    width: 170px;
  }

  .header-search span {
    width: auto;
    margin: 0;
  }

  .header-search span img {
    width: 170px;
  }

  .header-search.other .header-search__form {
    float: left;
    width: calc(81% - 210px);
    margin-top: 7px;
    margin-left: 3%;
    font-size: 14px;
    line-height: 40px;
  }

  .header-search.other .header-search__form input[type="text"] {
    width: 70%;
    height: 40px;
  }

  .header-search.other .header-search__form button[type="submit"] {
    width: 30%;
    height: 40px;
    line-height: 0;
  }

  .header-search.other .header-search__form button[type="submit"] img {
    height: 20px;
    padding-right: 7%;
  }

  .header-search__add-recipe {
    width: 19%;
    height: 40px;
    margin-top: 7px;
    line-height: 40px;
  }

  .header-search__add-recipe a {
    font-size: 14px;
  }

  .header-search__add-recipe a img {
    height: 30px;
    margin-right: 5%;
  }

  .header-search__links {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .header-search__links a:nth-last-child(2) {
    display: none;
  }

  .header-search.homepage {
    background-color: #373a3f;
    background-image: url("./../../dist/img/header-background.jpg");
    background-size: cover;
    height: 150px;
    padding: 48px 25px 0;
    position: static;
    top: 0;
  }

  .header-search h1 {
    float: left;
  }

  .header-search__image {
    width: 170px;
  }

  .header-search.homepage, .header-search.other {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    margin-top: 75px;
    padding: 0 10px;
  }

  .header-search span, .header-search h1 {
    display: none;
  }

  .header-search__form, .header-search.other .header-search__form {
    z-index: 1001;
    float: right;
    width: 50px;
    margin: 0;
    font-size: 18px;
    line-height: 60px;
    display: inline-block;
    position: fixed;
    top: 0;
    right: 55px;
  }

  .header-search__form input[type="text"] {
    width: calc(100% - 50px);
    height: 50px;
    display: none;
    position: absolute;
    right: 50px;
  }

  .header-search.other .header-search__form input[type="text"] {
    width: calc(100% - 50px);
    height: 50px;
    display: none;
    position: absolute;
    right: 50px;
  }

  .header-search__form button[type="submit"] {
    width: 50px;
    height: 50px;
    line-height: 47px;
    overflow: hidden;
  }

  .header-search.other .header-search__form button[type="submit"] {
    width: 50px;
    height: 50px;
    line-height: 47px;
    overflow: hidden;
  }

  .header-search__form button[type="submit"] img {
    height: 27px;
    padding-right: 0;
  }

  .header-search.other .header-search__form button[type="submit"] img {
    height: 27px;
    padding-right: 0;
  }

  .header-search__add-recipe {
    display: none;
  }

  .header-search__links {
    width: 100%;
  }
}

@media (max-width: 1080px) and (max-width: 1080px) {
  .header-search__links {
    display: none;
  }

  .header-search__links.expanded {
    display: block;
  }
}

.email__form {
  float: none;
  width: 100% !important;
}

@media (max-width: 850px) {
  .email__form input[type="submit"] {
    width: 130px;
    height: 47px;
    padding: 0;
    line-height: 47px;
  }

  .email__form input[type="email"] {
    width: calc(100% - 130px);
    height: 47px;
  }
}

.pipe {
  content: "|";
}

.pipe--before {
  margin-left: 15px !important;
}

.pipe--before:before {
  content: "|";
  color: #bdbdbd;
  text-decoration: none;
  display: inline-block;
  position: relative;
  left: -11px;
}

.pipe--after {
  margin-right: 15px !important;
}

.pipe--after:after {
  content: "|";
  color: #bdbdbd;
  text-decoration: none;
  display: inline-block;
  position: relative;
  left: 11px;
}

.article-link {
  border-bottom: 1px solid #ccc;
  padding: 20px 0;
  display: flex;
  overflow: auto;
}

.article-link .image-container {
  float: left;
  width: 322px;
  padding-bottom: 0;
  display: block;
  position: relative;
}

.article-link .image-container .silhouette-image div {
  background-color: #666;
  background-position: center;
  background-size: 80%;
  width: 322px;
  height: 215px;
}

.article-link .title-content {
  width: 352px;
}

.article-link .title-content h3 {
  float: left;
  color: #000;
  text-decoration: none;
}

.article-link .title-content h3 a {
  max-width: 320px;
  margin: 10px 0 0;
  padding-top: 0;
  padding-left: 0;
  font-size: 30px;
  line-height: 36px;
  transition: all .25s ease-in-out;
}

.article-link .title-content h3 a:hover {
  color: #fbb03b;
  text-decoration: underline;
}

.article-link--pr-standard {
  padding-top: 10px;
}

.top-line + .top-line .article-link {
  border-top: 0;
  padding-top: 5px;
}

.first .article-link {
  border-top: 1px solid #ccc;
}

.meta .category-name {
  vertical-align: middle;
  padding-top: 5px;
  display: inline-block;
}

.meta .category-name a {
  color: #fbb03b;
  font-size: 14px;
  line-height: 18px;
  text-decoration: none;
  transition: all .25s ease-in-out;
}

.meta .category-name a:hover {
  text-decoration: underline;
}

.article-link-simple h2 {
  margin-top: 0;
}

.article-link-simple h2 a {
  color: #fbb03b;
  text-decoration: underline;
}

.article-link-simple h2 a:hover {
  text-decoration: none;
}

.interesting-news__wrapper {
  background: #fff;
  max-width: 100%;
  padding: 30px 20px;
}

.interesting-news__wrapper:before, .interesting-news__wrapper:after {
  content: " ";
  display: block;
}

.interesting-news__wrapper:after {
  clear: both;
}

.interesting-news__item {
  background-color: #fff;
  margin: 0 0 20px;
  padding: 30px 20px;
}

.interesting-news__item:before, .interesting-news__item:after {
  content: " ";
  display: block;
}

.interesting-news__item:after {
  clear: both;
}

.interesting-news__item:last-child {
  margin: 0;
}

.interesting-news__item.idvert-wrapper .img-responsive {
  width: 100%;
}

.interesting-news__item .silhouette-image div {
  float: left;
  background-color: #666;
  background-position: center;
  background-size: 80%;
  width: 100%;
  max-width: 270px;
  height: 180px;
  margin-right: 30px;
}

.interesting-news__image {
  float: left;
  width: 100%;
  max-width: 270px;
  height: 180px;
  margin-right: 30px;
}

.interesting-news__homepage .interesting-news__item {
  padding: 0;
}

.interesting-news__top-two {
  background-color: #fff;
  width: 100%;
  margin-top: 30px;
  padding: 20px;
}

.interesting-news__top-two:before, .interesting-news__top-two:after {
  content: " ";
  display: block;
}

.interesting-news__top-two:after {
  clear: both;
}

.interesting-news__top-two div {
  float: right;
  width: 48%;
  margin: 0;
  padding: 0;
}

.interesting-news__top-two div img {
  float: left;
  background-color: #ccc;
  width: 50%;
  max-width: 270px;
  height: 168px;
  margin: 0 25px 0 0;
  display: inline-block;
}

.interesting-news__top-two div h3 {
  float: right;
  color: #fbb03b;
  width: calc(50% - 25px);
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: normal;
  transition: color .25s ease-in-out;
  display: block;
}

.interesting-news__top-two div h3:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.interesting-news__top-two div span {
  color: #ccc;
}

.interesting-news__top-two div span div:first-child {
  float: none;
  width: auto;
  margin: 0 16px;
  font-size: 10px;
  display: inline-block;
}

.interesting-news__top-two div p a {
  color: #fbb03b;
  text-decoration: underline;
  transition: color .25s ease-in-out;
}

.interesting-news__top-two div p a:hover {
  color: #ed1c24;
}

.interesting-news__top-two div:first-child {
  float: left;
}

.interesting-news__top-two .interesting-news__item {
  padding: 0;
}

.interesting-news__meta h3 {
  color: #fbb03b;
  margin: 5px 0;
  font-size: 22px;
  font-weight: normal;
  transition: color .25s ease-in-out;
}

.interesting-news__meta h3:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.interesting-news__meta h3 a {
  color: #fbb03b;
}

.interesting-news__meta p {
  margin: 10px 0;
}

.interesting-news__meta p a {
  color: #fbb03b;
  text-decoration: underline;
  transition: color .25s ease-in-out;
}

.interesting-news__meta p a:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.interesting-news__meta span {
  color: #a3a3a3;
}

.interesting-news__meta span a {
  color: #fbb03b;
  margin: 0 0 10px 5px;
}

.interesting-news__meta span a:hover {
  color: #ed1c24;
}

.interesting-news__meta div {
  color: #ccc;
  margin: 0 16px;
  font-size: 10px;
  display: inline-block;
}

@media (min-width: 1081px) {
  header {
    width: 1080px;
    margin: 0 auto;
  }

  #primary-menu {
    z-index: 2;
    background-color: #000;
    border-bottom: 1px solid #333;
    width: 100%;
    font-size: 14px;
    position: relative;
  }
}

@media (min-width: 1081px) and (max-width: 900px) {
  #primary-menu {
    font-size: 13px;
  }
}

@media (min-width: 1081px) {
  #primary-menu .main-menu {
    max-width: 1081px;
    margin: 0 auto;
    line-height: 50px;
  }

  #primary-menu .main-menu:before, #primary-menu .main-menu:after {
    content: " ";
    display: block;
  }

  #primary-menu .main-menu:after {
    clear: both;
  }

  #primary-menu .main-menu__links {
    float: left;
    background-color: #000;
    margin: 2px 0 0;
    padding: 0;
  }

  #primary-menu .main-menu__links li, #primary-menu .main-menu__actions-links li {
    display: inline-block;
  }

  #primary-menu .main-menu__links li a, #primary-menu .main-menu__actions-links li a {
    color: #fff;
    padding: 0 10px;
    transition: color .15s ease-in-out;
    display: block;
  }

  #primary-menu .main-menu__links li a:hover, #primary-menu .main-menu__actions-links li a:hover {
    color: #fbb03b;
    text-decoration: underline;
  }

  #primary-menu .main-menu__actions-links, #primary-menu .main-menu__social-links {
    float: right;
  }

  #primary-menu .main-menu__social-links a {
    margin-top: 2px;
    padding: 0 10px;
    display: inline-block;
  }

  #primary-menu .main-menu__social-links a svg {
    fill: #fff;
    width: 15px;
    height: 15px;
  }

  #primary-menu .main-menu__social-links a:hover path {
    fill: #fbb03b;
  }

  #primary-menu .main-menu__signin, #primary-menu .main-menu__settings {
    color: #ed1c24;
    transition: color .15s ease-in-out;
  }

  #primary-menu .main-menu__signin:hover, #primary-menu .main-menu__signin.expanded, #primary-menu .main-menu__settings:hover, #primary-menu .main-menu__settings.expanded {
    color: #fbb03b;
    text-decoration: underline;
  }

  #primary-menu .main-menu__addrecipe img {
    margin-top: -5px;
    margin-right: 20px;
  }

  #primary-menu .main-menu__sub-menu ul {
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    background-color: #fbb03b;
    margin: 0;
    padding: 30px 20px;
    transition: transform .3s ease-in-out, opacity .1s ease-in-out;
    position: absolute;
    transform: translateY(30px);
    box-shadow: 5px 3px 10px rgba(0, 0, 0, .1);
  }

  #primary-menu .main-menu__sub-menu ul:before {
    content: "";
    background-color: #fbb03b;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -7px;
    left: 45px;
    transform: rotate(45deg);
  }

  #primary-menu .main-menu__sub-menu ul li {
    line-height: 36px;
    display: block;
  }

  #primary-menu .main-menu__sub-menu ul li a {
    color: #000;
  }

  #primary-menu .main-menu__sub-menu ul li a:hover {
    color: #fff;
  }
}

@media (min-width: 1081px) and (min-width: 767px) and (max-width: 1196px) {
  #primary-menu .main-menu__sub-menu ul.main-menu__sub-menu--profile {
    right: 0;
  }
}

@media (min-width: 1081px) {
  #primary-menu .main-menu__sub-menu:hover ul {
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
  }

  #primary-menu .main-menu__signin-form {
    visibility: hidden;
    opacity: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform-origin: top;
    background-color: #ed1c24;
    width: 325px;
    margin-left: -120px;
    padding: 40px;
    line-height: 0;
    position: absolute;
    transform: scale(0);
    box-shadow: 5px 3px 10px rgba(0, 0, 0, .1);
  }

  #primary-menu .main-menu__signin-form:before {
    content: "";
    background-color: #ed1c24;
    width: 14px;
    height: 14px;
    position: absolute;
    top: -6px;
    left: 155.5px;
    transform: rotate(45deg);
  }

  #primary-menu .main-menu__signin-form.expanded {
    visibility: visible;
    animation: .5s forwards bounce;
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(0);
    }

    50% {
      opacity: 1;
      transform: scale(1.1);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  #primary-menu .main-menu__signin-form-wrapper {
    position: relative;
  }

  #primary-menu .main-menu__signin-form input {
    width: 100%;
  }

  #primary-menu .main-menu__signin-form input[type="text"] {
    border: 1px solid #d11118;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 40px;
    display: block;
  }

  #primary-menu .main-menu__signin-form input[type="password"] {
    border: 1px solid #d11118;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 40px;
    display: block;
  }

  #primary-menu .main-menu__signin-form input[type="submit"] {
    margin-bottom: 35px;
  }

  #primary-menu .main-menu__signin-form a {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
  }

  #primary-menu .main-menu__signin-form a.main-menu__create-account {
    float: right;
  }

  #primary-menu .main-menu__signin-form a:hover {
    text-decoration: none;
  }

  #primary-menu .main-menu__user-icon {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  #primary-menu .main-menu__password-icon {
    position: absolute;
    top: 72px;
    right: 10px;
  }

  #primary-menu .main-menu__close-button, #primary-menu .main-menu__mobile, #primary-menu .main-menu__mobile-links {
    display: none !important;
  }
}

@media (min-width: 1081px) and (max-width: 1148px) {
  #primary-menu .main-menu {
    margin: 0 25px;
  }
}

@media (min-width: 1081px) and (max-width: 1081px) {
  #primary-menu .main-menu__links {
    margin: 0 -10px;
  }

  #primary-menu .main-menu__links li a {
    padding: 0 10px;
  }

  #primary-menu .main-menu__sub-menu ul {
    padding: 20px;
  }
}

@media (min-width: 1081px) and (max-width: 980px) {
  #primary-menu .main-menu {
    line-height: 60px;
  }

  #primary-menu .main-menu__social-links.mobile {
    display: none;
  }

  #primary-menu .main-menu__links {
    margin: 0 -7px;
  }

  #primary-menu .main-menu__links li a {
    padding: 0 7px;
  }

  #primary-menu .main-menu__signin {
    margin-right: 15px;
  }

  #primary-menu .main-menu__addrecipe {
    padding: 13px 15px;
  }

  #primary-menu .main-menu__addrecipe img {
    height: 30px;
    margin-right: 15px;
  }

  #primary-menu .main-menu__sub-menu ul {
    padding: 15px;
  }

  #primary-menu .main-menu__signin-form {
    margin-left: -130px;
  }
}

@media (max-width: 1080px) {
  header .cnc-menu {
    z-index: 1;
  }

  .main-menu__actions-links__signin, .main-menu__actions-links__addrecipe, .main-menu__social-links {
    display: none;
  }

  .main-menu__social-links.mobile {
    display: block;
  }

  .main-menu__social-links.mobile a {
    padding: 0 10px;
    display: inline-block;
  }

  .main-menu__social-links.mobile a svg {
    fill: #fff;
    width: 25px;
    height: 25px;
  }

  body {
    padding-top: 75px;
  }

  .header-wrapper {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
  }

  #primary-menu {
    border-bottom: 0;
  }

  #primary-menu .main-menu {
    z-index: 1000;
    background-color: #fff;
    width: 100%;
    line-height: 0;
    display: block;
    position: fixed;
    top: 0;
  }

  #primary-menu .main-menu:before {
    content: "";
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, .4);
    width: 100%;
    height: 100%;
    transition: background .3s ease-in-out, opacity .3s ease-in-out;
    position: fixed;
    top: 0;
    left: 0;
  }

  #primary-menu .main-menu.expanded:before {
    content: "";
    opacity: 1;
    visibility: visible;
  }

  #primary-menu .main-menu__mobile {
    z-index: 1;
    background-color: #fff;
    width: 100%;
    max-height: 50px;
    line-height: 0;
    position: fixed;
  }

  #primary-menu .main-menu__mobile-btn {
    cursor: pointer;
    background-color: #ed1c24;
    border: 0;
    width: 65px;
    height: 50px;
    padding: 13px 19px;
    position: relative;
    overflow: hidden;
  }

  #primary-menu .main-menu__mobile-btn span {
    opacity: 1;
    will-change: transform;
    background: #fff;
    border-radius: 5px;
    width: 100%;
    height: 3px;
    transition: all .25s ease-in-out;
    display: block;
    position: absolute;
    left: 0;
    transform: rotate(0);
  }

  #primary-menu .main-menu__mobile-btn span:first-child {
    top: 0;
  }

  #primary-menu .main-menu__mobile-btn span:nth-child(2) {
    top: 10px;
  }

  #primary-menu .main-menu__mobile-btn span:nth-child(3) {
    top: 20px;
  }

  #primary-menu .main-menu__mobile-btn:hover, #primary-menu .main-menu__mobile-btn:focus, #primary-menu .main-menu__mobile-btn:active {
    outline: none;
  }

  #primary-menu .main-menu.expanded .main-menu__mobile-btn span:first-child {
    top: 10px;
    transform: rotate(135deg);
  }

  #primary-menu .main-menu.expanded .main-menu__mobile-btn span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  #primary-menu .main-menu.expanded .main-menu__mobile-btn span:nth-child(3) {
    top: 10px;
    transform: rotate(-135deg);
  }

  #primary-menu .main-menu__mobile-icon {
    height: 100%;
    position: relative;
  }

  #primary-menu .main-menu__logo-image {
    display: inline-block;
    position: relative;
    top: -7px;
    left: 10px;
  }

  #primary-menu .main-menu__logo-image img {
    width: 110px;
    max-height: 26px;
  }

  #primary-menu .main-menu__links {
    will-change: transform;
    -webkit-user-select: none;
    user-select: none;
    background-color: #ed1c24;
    width: 90%;
    max-width: 350px;
    height: 100%;
    margin: 0;
    padding: 20px 0 60px;
    font-size: 20px;
    list-style: none;
    position: fixed;
    top: 50px;
    overflow: auto;
    transform: translateX(-105%);
    box-shadow: 2px 0 12px rgba(0, 0, 0, .4);
  }

  #primary-menu .main-menu__links--animatable {
    transition: transform .13s ease-in-out;
  }

  #primary-menu .main-menu__links li {
    border-bottom: 1px solid #d11118;
    padding: 20px 25px;
    position: relative;
  }

  #primary-menu .main-menu__links li:last-child {
    border-bottom: 0;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu {
    max-height: 58px;
    transition: max-height .5s ease-in-out, background-color .3s ease-in-out;
    overflow: hidden;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu.sub-menu--expanded {
    background-color: #af161c;
    max-height: 500px;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu.sub-menu--expanded:after {
    background-image: url("../../../../images/receptycz/dist/non-sprite/plus-2.svg");
    transform: rotate(45deg);
  }

  #primary-menu .main-menu__links .main-menu__sub-menu.sub-menu--expanded > a {
    color: #fbb03b;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu:after {
    content: "";
    cursor: pointer;
    background-image: url("../../../../images/receptycz/dist/non-sprite/plus.svg");
    width: 36px;
    height: 36px;
    transition: transform .3s ease-in-out;
    position: absolute;
    top: 10px;
    right: 15px;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu > ul {
    margin: 0;
    padding: 15px 0 0;
    font-size: 16px;
    list-style: none;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu > ul li {
    border-bottom: 0;
    padding: 13px 0;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu > ul li:last-child {
    padding-bottom: 0;
  }

  #primary-menu .main-menu__links .main-menu__sub-menu.sub-menu--expanded > ul {
    display: block;
  }

  #primary-menu .main-menu__links a {
    color: #fff;
    line-height: 17px;
  }

  #primary-menu .main-menu.expanded .main-menu__links {
    transform: translateX(0);
  }

  #primary-menu .main-menu.expanded .main-menu__links--animatable {
    transition: transform .3s ease-in-out;
  }

  #primary-menu .main-menu__signin-form {
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    will-change: transform, opacity;
    background-color: #ed1c24;
    width: 100%;
    height: 100vh;
    line-height: 0;
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    transform: scale(.5);
  }

  #primary-menu .main-menu__signin-form.expanded {
    visibility: visible;
    animation: .4s .13s forwards bounce;
  }

  @keyframes bounce {
    0% {
      opacity: 0;
      transform: scale(.5);
    }

    50% {
      opacity: 1;
      transform: scale(1.03);
    }

    100% {
      opacity: 1;
      transform: scale(1);
    }
  }

  #primary-menu .main-menu__signin-form-wrapper {
    width: 90%;
    max-width: 350px;
    margin: 100px auto;
    position: relative;
  }

  #primary-menu .main-menu__signin-form input {
    width: 100%;
  }

  #primary-menu .main-menu__signin-form input[type="text"] {
    border: 1px solid #d11118;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 40px;
    display: block;
  }

  #primary-menu .main-menu__signin-form input[type="password"] {
    border: 1px solid #d11118;
    height: 40px;
    margin-bottom: 20px;
    padding: 0 15px;
    font-size: 14px;
    line-height: 40px;
    display: block;
  }

  #primary-menu .main-menu__signin-form input[type="submit"] {
    margin-bottom: 35px;
  }

  #primary-menu .main-menu__signin-form a {
    color: #000;
    font-size: 14px;
    text-decoration: underline;
  }

  #primary-menu .main-menu__signin-form a.main-menu__create-account {
    float: right;
  }

  #primary-menu .main-menu__signin-form a:hover {
    text-decoration: none;
  }

  #primary-menu .main-menu__user-icon {
    position: absolute;
    top: 12px;
    right: 10px;
  }

  #primary-menu .main-menu__password-icon {
    position: absolute;
    top: 72px;
    right: 10px;
  }

  #primary-menu .main-menu__close-button {
    width: 80px;
    height: 80px;
    padding: 22px;
    position: fixed;
    top: 0;
    right: 0;
  }

  #primary-menu .main-menu__close-button img {
    cursor: pointer;
  }
}

form.default-form.wikisearch input[type="text"] {
  float: left;
  border: 1px solid rgba(0, 0, 0, .2);
  border-right: none;
  min-width: 336px;
}

form.default-form.wikisearch button.button-search {
  color: #fff;
  float: left;
  background: #fbb03b;
  border: 0;
  outline: none;
  min-width: 97px;
  min-height: 35px;
  transition: background-color .25s ease-in-out;
}

form.default-form.wikisearch button.button-search:hover {
  background-color: #fa9e0d;
}

.pagination {
  width: 100%;
  margin: 30px 0;
  display: table;
}

.pagination .prev-page, .pagination .next-page {
  text-align: center;
  vertical-align: top;
  width: 40px;
  display: table-cell;
}

.pagination .prev-page a, .pagination .next-page a {
  background-color: #fbb03b;
  border-radius: 3px;
  width: 40px;
  height: 40px;
  transition: background-color .2s;
  display: inline-block;
}

.pagination .prev-page a span, .pagination .next-page a span {
  background-position: center;
  background-size: 45%;
  width: 30px;
  height: 40px;
  display: inline-block;
}

.pagination .prev-page a:hover, .pagination .next-page a:hover {
  background-color: #ed1c24;
}

.pagination .page-nums {
  text-align: center;
  vertical-align: top;
  display: table-cell;
}

.pagination .page-nums a {
  color: #fbb03b;
  padding: 0 15px;
  font-size: 15px;
  line-height: 40px;
  display: inline-block;
}

.pagination .page-nums a.aktpage {
  color: #ed1c24;
}

.pagination .page-nums a:hover {
  text-decoration: underline;
}

.ajax-pagination .main-button {
  margin: 30px auto;
  font-size: 15px;
}

article + script + .first.top-line .article-link, article + script + script + .first.top-line .article-link {
  border-top: 0;
}

#paginator {
  text-align: center;
  border-top: 1px solid #fbb03b;
  width: 100%;
  margin-top: 15px;
  padding-top: 20px;
}

#paginator a {
  color: #fbb03b;
  padding: 5px;
  text-decoration: none;
}

#paginator--item-actual {
  border: 1px solid #fbb03b;
  padding: 5px;
  font-weight: bold;
}

.image-popup {
  cursor: pointer;
  z-index: 9999;
  visibility: hidden;
  position: absolute;
}

.image-popup img {
  max-width: 100%;
}

.image-popup .close-popup {
  text-align: right;
  color: #fff;
  text-transform: uppercase;
  background: #fbb03b;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  font-size: 15px;
  font-weight: normal;
  line-height: 32px;
  text-decoration: none;
}

.image-popup .close-popup .ico {
  vertical-align: middle;
  margin: 0 10px 0 3px;
  padding-bottom: 10px;
  font-size: 40px;
  font-weight: 100;
  display: inline-block;
  transform: rotate(45deg);
}

.image-popup .description {
  color: #fff;
  background: #fbb03b;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  padding: 10px;
  font-size: 15px;
}

.image-popup .description span {
  margin-top: 5px;
  font-size: 15px;
  display: inline-block;
}

.recommended-recipes {
  width: 300px;
  margin: 0 auto;
}

.recommended-recipes h3 {
  padding-left: 15px;
}

.recommended-recipes.recommended-recipes__horizontal {
  width: 100%;
  margin: 0 0 20px;
}

.recommended-recipes.recommended-recipes__horizontal h3 {
  padding-left: 0;
}

.recommended-recipes.recommended-recipes__horizontal .recommended-recipes__sidebar {
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
  padding: 0;
  display: flex;
}

.recommended-recipes.recommended-recipes__horizontal .recommended-recipes__sidebar .recommended-recipes__item {
  width: 300px;
}

.recommended-recipes__item {
  float: left;
  width: calc(270.25px + 5);
  padding-right: 20px;
  transition: all .25s ease-in-out;
  display: block;
}

.recommended-recipes__item .video-list__item {
  transition: all .25s ease-in-out;
}

.recommended-recipes__item .video-list__item .video-list__author {
  margin: 0;
  padding: 0;
  transition: all .35s ease-in-out;
  position: absolute;
  top: 0;
  left: 5px;
}

.recommended-recipes__item .video-list__item .video-list__author a {
  color: #fbb03b;
  margin-left: 5px;
}

.recommended-recipes__item .video-list__item .recommended-recipes__article-meta {
  transition: all .25s ease-in-out;
}

.recommended-recipes__item .video-list__item .recommended-recipes__article-meta .video-list__play {
  transition: all .25s ease-in-out;
  bottom: 50px;
}

.recommended-recipes__item .video-list__item:hover .video-list__author {
  opacity: 0;
}

.recommended-recipes__item .video-list__item:hover .recommended-recipes__article-meta a:nth-child(2) span {
  color: #fbb03b;
}

.recommended-recipes__item span {
  color: #fff;
}

.recommended-recipes__article:hover .recommended-recipes__time {
  opacity: 0;
}

.recommended-recipes__article {
  position: relative;
  overflow: hidden;
}

.recommended-recipes__article:hover .recommended-recipes__article-meta p {
  color: #fbb03b;
}

.recommended-recipes__overlay {
  z-index: 2;
  background: none;
  background: linear-gradient(rgba(0, 0, 0, 0) 65%, rgba(0, 0, 0, .5) 100%);
  width: 100%;
  height: 100%;
  transition: background .4s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.recommended-recipes__image {
  width: 100%;
}

.recommended-recipes__article-meta {
  z-index: 3;
  text-align: center;
  width: 100%;
  font-size: 17px;
  position: absolute;
  bottom: 16px;
}

.recommended-recipes__article-meta p {
  color: #fff;
  text-shadow: 1px 1px 2px #000;
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;
  font-weight: 500;
  transition: color .25s ease-in-out;
}

.recommended-recipes__article-meta p:hover {
  color: #fbb03b !important;
}

.recommended-recipes__scrollbar {
  will-change: transform;
  width: 100%;
  height: 35px;
  margin-top: 35px;
  position: relative;
}

.recommended-recipes__scrollbar:before {
  content: "";
  background: #d6d6d6;
  width: 100%;
  max-width: 1081px;
  height: 6px;
  position: absolute;
  top: calc(50% - 3px);
}

.recommended-recipes__scrollbar-rider {
  will-change: transform;
  cursor: grab;
  background-color: #fbb03b;
  width: 118px;
  height: 100%;
  position: relative;
}

.recommended-recipes__scrollbar-rider:before {
  content: "";
  background: url("../img/burger_v.svg") no-repeat;
  width: 15px;
  height: 15px;
  position: absolute;
  top: calc(50% - 7.5px);
  left: calc(50% - 7.5px);
}

.recommended-recipes__scrollbar-rider:active {
  cursor: grabbing;
}

.recommended-recipes__sidebar {
  background: #fff;
  padding: 15px 15px 0;
}

.recommended-recipes__sidebar:before, .recommended-recipes__sidebar:after {
  content: " ";
  display: block;
}

.recommended-recipes__sidebar:after {
  clear: both;
}

.recommended-recipes__sidebar .recommended-recipes__item {
  float: none;
  width: auto;
  padding: 0 0 15px;
}

@media (max-width: 1050px) {
  .recommended-recipes__article-meta {
    margin-bottom: -49px;
    font-size: 16px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 30px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 14px 20px;
  }
}

@media (max-width: 980px) {
  .recommended-recipes__mail a {
    font-size: 15px;
  }

  .recommended-recipes__mail a:after {
    width: 26px;
    height: 26px;
    margin-top: -4px;
  }

  .recommended-recipes__scrollbar {
    height: 28px;
  }
}

@media (max-width: 950px) {
  .recommended-recipes__article-meta {
    margin-bottom: -47px;
    font-size: 15px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 25px;
  }
}

@media (max-width: 900px) {
  .recommended-recipes__item {
    padding-right: 16px;
  }

  .recommended-recipes__article-meta {
    font-size: 14px;
  }

  .recommended-recipes__article-meta p {
    line-height: 20px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 12px 18px;
  }

  .recommended-recipes__scrollbar-rider {
    width: 90px;
  }
}

@media (max-width: 850px) {
  .recommended-recipes__article-meta {
    margin-bottom: -37px;
    font-size: 13px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 20px;
    line-height: 16px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 7px 12px 8px;
  }
}

@media (max-width: 767px) {
  .recommended-recipes__row {
    max-width: 100%;
  }

  .recommended-recipes__item {
    width: 33.3333%;
    padding: 0 0 4%;
  }

  .recommended-recipes__item:nth-child(3n+1) {
    padding-right: 2%;
  }

  .recommended-recipes__item:nth-child(3n+2) {
    padding: 0 1%;
  }

  .recommended-recipes__item:nth-child(3n+3) {
    padding-left: 2%;
  }

  .recommended-recipes__scrollbar {
    display: none;
  }
}

@media (max-width: 600px) {
  .recommended-recipes__item {
    width: 50%;
  }

  .recommended-recipes__item:nth-child(odd) {
    padding: 0 3% 4% 0;
  }

  .recommended-recipes__item:nth-child(2n) {
    padding: 0 0 4% 3%;
  }

  .recommended-recipes__article-meta {
    margin-bottom: -56px;
    font-size: 17px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 46px;
    line-height: 22px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 16px 35px;
  }

  .recommended-recipes__scrollbar {
    display: none;
  }
}

@media (max-width: 580px) {
  .recommended-recipes__article-meta {
    margin-bottom: -40px;
    font-size: 14px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 20px;
    line-height: 20px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 7px 12px 8px;
  }
}

@media (max-width: 480px) {
  .recommended-recipes__mail {
    display: none;
  }

  .recommended-recipes__article-meta {
    font-size: 13px;
  }

  .recommended-recipes p {
    line-height: 16px;
  }
}

@media only screen and (max-width: 400px), (max-width: 370px) {
  .recommended-recipes__item {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  .recommended-recipes__article-meta {
    margin-bottom: -56px;
    font-size: 20px;
  }

  .recommended-recipes__article-meta p {
    margin: 0 auto 46px;
    line-height: 28px;
  }

  .recommended-recipes__article-meta a[type="button"] {
    padding: 16px 35px;
  }
}

.search-results .video-list__wrapper {
  padding: 0;
}

.recommended-recipes__homepage .recommended-recipes__item:nth-child(2) .recommended-recipes__article-meta p {
  font-size: 24px;
}

@media (max-width: 820px) {
  .recommended-recipes__homepage .recommended-recipes__item:nth-child(2) .recommended-recipes__article-meta p {
    font-size: 21px;
  }
}

.list-article-topbox {
  margin: 20px 0;
  display: table;
}

.list-article-topbox a {
  color: #fbb03b;
  text-decoration: none;
}

.list-article-topbox a:hover {
  color: #ed1c24;
  text-decoration: underline;
}

.list-article-topbox .article-top {
  vertical-align: top;
  width: 530px;
  display: table-cell;
}

.list-article-topbox .article-top h2 {
  font-size: 30px;
}

.list-article-topbox .article-top > div {
  border-right: 1px solid #ccc;
  margin: 0 15px 0 0;
  padding: 0 15px 0 0;
}

.list-article-topbox .article-side {
  vertical-align: top;
  max-width: 148px;
  display: table-cell;
}

.list-article-topbox .article-side h2 {
  font-size: 15px;
}

.list-article-topbox .article-side .list-article {
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.list-article-topbox .article-side .list-article:first-of-type {
  border-top: 0;
  padding-top: 0;
}

.search span.title {
  margin-right: 10px;
  font-size: 17px;
  font-weight: normal;
  line-height: 36px;
  display: block;
}

.search h1.title {
  margin: 0 0 20px;
  font-size: 24px;
  font-weight: normal;
}

.search .tags h1.title {
  font-weight: bold;
}

.search p {
  font-size: 15px;
}

.article-listed {
  font-size: 22px;
  line-height: 24px;
}

.article-listed button {
  position: relative;
}

.article-listed .shares-and-date .date, .article-listed .authors-simple {
  font-size: 14px;
}

.article-listed ul.main {
  display: none;
}

.article-listed ul.main .perex {
  margin-top: 20px;
  margin-bottom: 0;
  font-size: 17px;
}

.article-listed ul.main .perex p {
  padding-left: 0;
  font-size: 17px;
}

.article-listed .triangle-right {
  float: right;
}

.article-listed .triangle-left {
  float: left;
}

.article-listed .keywords {
  font-size: 14px;
}

.article-listed.notlisted ul.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article-listed.notlisted .listing-control-buttons {
  display: none;
}

.article-listed.listed ul.main {
  margin: 0;
  padding: 0;
  list-style: none;
}

.article-listed.listed ul.main > li {
  display: none;
  overflow: hidden;
}

.article-listed.listed ul.main > li.active {
  display: block;
}

.article-listed .bottom {
  text-align: center;
  min-height: 70px;
  margin: 20px 0;
}

.article-listed .bottom .sections-paging button {
  display: inline-block;
}

.article-listed .bottom .sections-paging button.show-prev {
  float: left;
}

.article-listed .bottom .sections-paging button.show-next, .article-listed .bottom .sections-paging button.start.show-start {
  float: right;
}

.article-listed .bottom .sections-paging button.start-listing {
  float: none;
}

.article-listed .artsection-top h2 {
  max-width: 350px;
  margin-bottom: 30px;
}

.article-listed .artsection-top .sections-paging button.show-next, .article-listed .artsection-top .sections-paging button.start.show-start {
  position: absolute;
  right: 0;
}

.unimenu {
  z-index: 3;
  position: relative;
}

.unimenu__menu {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.unimenu__item {
  margin: 0;
  padding: 0 3px;
  list-style: none;
  display: inline-block;
  position: relative;
}

.unimenu__item:hover .unimenu__list {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.unimenu__item.unimenu__item-divider {
  padding-left: 15px;
}

.unimenu__item.unimenu__item-divider:before {
  content: "|";
  color: #bdbdbd;
  text-decoration: none;
  display: inline-block;
  position: absolute;
  left: 3px;
}

.unimenu__link {
  color: #fbb03b;
  font-size: 14px;
  display: inline-block;
}

.unimenu__list {
  visibility: hidden;
  opacity: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: #fbb03b;
  transition: transform .3s ease-in-out, opacity .1s ease-in-out;
  position: absolute;
  transform: translateY(30px);
}

.unimenu__list:before {
  content: "";
  background-color: #fbb03b;
  width: 14px;
  height: 14px;
  position: absolute;
  top: -7px;
  left: 45px;
  transform: rotate(45deg);
}

.unimenu__list .unimenu__link {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #000;
  width: 100%;
  font-size: 14px;
  overflow: hidden;
}

.unimenu__list .unimenu__link:hover {
  color: #fff;
}

.unimenu__list .unimenu__item {
  width: 100%;
  padding: 0;
}

.unimenu--links {
  text-align: center;
  height: 18px;
  padding: 0;
  display: none;
  overflow: hidden;
}

.unimenu--links .unimenu__link {
  color: #fbb03b;
  padding-bottom: 15px;
  text-decoration: underline;
}

.unimenu--links .unimenu__link:hover {
  text-decoration: none;
}

.unimenu--links .unimenu__list {
  background-color: #fbb03b;
  margin: 0;
  padding: 30px 20px;
  box-shadow: 5px 3px 10px rgba(0, 0, 0, .1);
}

.unimenu--links .unimenu__list:before {
  left: 25px;
}

.unimenu--links .unimenu__list .unimenu__item:last-of-type .unimenu__link {
  padding-bottom: 0;
}

.unimenu--links .unimenu__list .unimenu__link {
  color: #000;
}

.unimenu--links .unimenu__list .unimenu__link:hover {
  color: #fff;
}

.header-search__links .unimenu--links {
  display: block;
}

.box-admin {
  margin: auto;
}

.box-admin article {
  float: left;
  width: 19%;
  padding: 0 15px;
  position: relative;
  overflow: hidden;
}

.box-admin article a, .box-admin article .box-admin__link-mag {
  color: #ed1c24;
  font-size: 19px;
  font-weight: 500;
  line-height: 40px;
}

.box-admin article h4 {
  max-width: 172px;
  height: 81px;
  margin: 8px 0 0;
  overflow: hidden;
}

.box-admin article h4 a {
  color: #fbb03b;
  font-size: 15px;
  font-weight: 500;
  line-height: 20px;
}

.loading-placeholder {
  width: 100%;
  display: block;
  position: relative;
  overflow: hidden;
}

.loading-placeholder img {
  opacity: 0;
  width: 100%;
  transition: opacity 1s;
}

.loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.loading-placeholder.item-loaded img {
  opacity: 1;
}

.loading-placeholder:not(.item-loaded) {
  background-color: #e8e8e8;
  height: auto;
  position: relative;
}

.loading-placeholder:not(.item-loaded):after {
  content: " ";
  background-color: #e8e8e8;
  background-image: url("https://img2.cncenter.cz/images/default/lazy-load/lazyload_placeholder.gif");
  background-repeat: repeat-y;
  background-size: 100% 1px;
  width: 100%;
  height: 100%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.loading-placeholder:not(.item-loaded) img {
  text-indent: -9999px;
  color: rgba(0, 0, 0, 0);
  position: absolute;
}

@keyframes gradient-animation {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(100%);
  }
}

.interesting-news__item .loading-placeholder {
  float: left;
  width: 270px;
  height: 180px;
  margin-right: 30px;
  padding-bottom: 0 !important;
}

@media (max-width: 740px) {
  .interesting-news__item .loading-placeholder {
    width: 100%;
  }

  .interesting-news__meta.title-content {
    float: left;
  }
}

.box-admin img.lazyload {
  height: auto;
}

.cnc-gallery-header {
  z-index: 1;
  background-color: #464646;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
  line-height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  overflow: visible;
}

.cnc-gallery-header__wrap {
  width: 100%;
  max-width: 1080px;
  height: 40px;
  margin: 0 auto;
  position: relative;
}

.cnc-gallery-header__logo {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translate(0, -50%);
}

.cnc-gallery-header__logo img {
  width: auto;
  height: 28px;
}

.cnc-gallery-header__logo img:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-header__title, .cnc-gallery-header__articles-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #fff;
  width: 60%;
  margin: 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 40px;
  display: none;
  position: relative;
  overflow: hidden;
}

.cnc-gallery-header__resizer {
  cursor: pointer;
  background: none;
  flex-wrap: wrap;
  place-content: space-between;
  align-items: center;
  width: 18px;
  height: 18px;
  display: flex;
  position: absolute;
  top: 50%;
  right: 85px;
  transform: translate(0, -50%);
}

.cnc-gallery-header__resizer:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-header__resizer div {
  background: #fff;
  width: 8px;
  height: 8px;
}

.cnc-gallery-header__resizer.cnc-gallery-header__resizer--resized {
  flex-wrap: nowrap;
  height: 14px;
}

.cnc-gallery-header__resizer.cnc-gallery-header__resizer--resized div {
  width: 4px;
  height: 100%;
}

.cnc-gallery-header__resizer.cnc-gallery-header__resizer--resized div:nth-child(2) {
  width: 8px;
}

.cnc-gallery-header__resizer.cnc-gallery-header__resizer--resized div:last-child {
  display: none;
}

.cnc-gallery-header__close-btn {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translate(0, -50%);
}

.cnc-gallery-header__close-btn:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-header__close-btn:before, .cnc-gallery-header__close-btn:after {
  content: " ";
  background-color: #fff;
  width: 2px;
  height: 20px;
  display: block;
  position: absolute;
  right: 5px;
}

.cnc-gallery-header__close-btn:before {
  transform: rotate(45deg);
}

.cnc-gallery-header__close-btn:after {
  transform: rotate(-45deg);
}

.cnc-gallery-header__counter {
  position: absolute;
  top: 50%;
  right: 120px;
  transform: translate(0, -50%);
}

.cnc-gallery-header__repeat {
  cursor: pointer;
  background-color: #1e1e1e;
  border-radius: 30px;
  align-items: center;
  padding: 5px 11px;
  font-size: 14px;
  line-height: 20px;
  display: none;
  position: absolute;
  top: 50%;
  right: 120px;
  transform: translate(0, -50%);
}

.cnc-gallery-header__repeat.shown {
  display: inline-flex;
}

.cnc-gallery-header__repeat .dataimg-reload-btn {
  margin-right: 5px;
}

@media only screen and (min-width: 768px) {
  .cnc-gallery-header__title {
    display: inline-block;
  }
}

@media only screen and (min-width: 992px) {
  .cnc-gallery-header__wrap {
    width: auto;
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .cnc-gallery-header__wrap {
    width: auto;
    max-width: 95%;
  }
}

.cnc-gallery-sharer__layer, .cnc-gallery-sharer__wrap {
  box-sizing: border-box;
  width: 58px;
  height: 300px;
  padding-top: 40px;
}

.cnc-gallery-sharer__sm {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 50%;
  width: 38px;
  height: 38px;
  margin-bottom: 5px;
  display: inline-block;
}

.cnc-gallery-sharer__sm svg {
  height: inherit;
  width: inherit;
}

.cnc-gallery-sharer__sm:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-sharer__sm.cnc-gallery-sharer__sm--inactive {
  display: none;
}

.cnc-gallery-sharer__icon {
  cursor: pointer;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 45%;
  right: 50px;
  transform: translate(0, -50%);
}

.cnc-gallery-sharer__icon:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-sharer__channels {
  line-height: normal;
  transition: all 1s;
  display: none;
}

.cnc-gallery-sharer__layer {
  text-align: center;
  opacity: .6;
  background-color: #1e1e1e;
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 30px;
}

.cnc-gallery-sharer__wrap {
  text-align: center;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 30px;
  position: absolute;
  top: 0;
  right: 30px;
}

@media only screen and (min-width: 768px) {
  .cnc-gallery-sharer__layer, .cnc-gallery-sharer__wrap {
    box-sizing: border-box;
    width: 240px;
    height: 36px;
    padding-right: 30px;
    line-height: 36px;
    top: 3px;
    right: 40px;
  }

  .cnc-gallery-sharer__layer {
    opacity: 1;
    padding-top: 0;
  }

  .cnc-gallery-sharer__wrap {
    padding-top: 3px;
  }

  .cnc-gallery-sharer__sm {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    margin: 0;
  }

  .cnc-gallery-sharer__sm svg {
    height: inherit;
    width: inherit;
  }
}

.cnc-gallery-slider {
  z-index: 0;
  margin-top: 40px;
  padding: 0 15px;
  overflow: hidden;
}

.cnc-gallery-slider img[data-src], .cnc-gallery-slider img[data-srcset] {
  min-height: 1px;
  margin: 0 auto;
  display: block;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__wrap {
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-info {
  display: none;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap {
  text-align: center;
  background: none;
  width: 140px;
  height: 89px;
  margin-bottom: 15px;
  overflow: hidden;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap > div {
  background-color: #1e1e1e;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap img {
  width: 140px;
  height: auto;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap .cnc-gallery-slider__placeholder {
  width: 30px;
  height: 30px;
  top: 35px;
  left: 50%;
  transform: translate(-50%);
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap.cnc-gallery-slider__img-wrap--hidden {
  visibility: hidden;
  height: 1px;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__enlarge-btn {
  display: none;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__nav--left, .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__nav--right {
  visibility: hidden;
  display: none;
}

.cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__ad {
  display: none;
}

.cnc-gallery-slider__title {
  color: #fff;
  margin: 0;
  padding: 10px 0;
  font-family: Open Sans, sans-serif;
  font-size: 16px;
  font-weight: normal;
  line-height: 20.8px;
}

.cnc-gallery-centerzone {
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  height: 10px;
  display: block;
  position: fixed;
  top: 50%;
  left: 0;
}

.cnc-gallery-slider__img-wrap {
  text-align: center;
  background-color: #464646;
  margin: 0 0 25px;
  padding: 0;
}

.cnc-gallery-slider__img-wrap > div {
  background-color: #000;
  min-height: 150px;
  display: block;
  position: relative;
}

.cnc-gallery-slider__img {
  cursor: pointer;
  text-align: center;
  width: 100%;
  font-size: 14px;
  line-height: 50px;
}

.cnc-gallery-slider__img + .cnc-gallery-slider__placeholder {
  display: block;
}

.cnc-gallery-slider__img:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-slider__img.lazyload ~ .cnc-gallery-slider__img-info, .cnc-gallery-slider__img.loaded ~ .cnc-gallery-slider__img-info {
  visibility: hidden;
}

.cnc-gallery-slider__img.lazyloaded + .cnc-gallery-slider__placeholder, .cnc-gallery-slider__img.loaded + .cnc-gallery-slider__placeholder {
  display: none;
}

.cnc-gallery-slider__img.lazyloaded ~ figcaption.cnc-gallery-slider__img-info, .cnc-gallery-slider__img.loaded ~ figcaption.cnc-gallery-slider__img-info {
  visibility: visible;
}

.cnc-gallery-slider__img.cnc-gallery-slider__img--full {
  z-index: 99;
  cursor: default;
  pointer-events: none;
  max-width: 100%;
  max-height: 100%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (orientation: landscape) {
  .cnc-gallery-slider__img.cnc-gallery-slider__img--full {
    width: auto;
    height: 100%;
  }
}

@media only screen and (orientation: portrait) {
  .cnc-gallery-slider__img.cnc-gallery-slider__img--full {
    width: 100%;
    height: auto;
  }
}

.cnc-gallery-slider__enlarge-btn {
  visibility: visible;
  cursor: pointer;
  opacity: .6;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  display: block;
  position: absolute;
  top: 10px;
  right: 10px;
}

.cnc-gallery-slider__enlarge-btn:hover {
  opacity: 1;
}

.cnc-gallery-slider__placeholder {
  width: 40px;
  height: 40px;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cnc-gallery-full-modal {
  z-index: 10000;
  background: #000;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.cnc-gallery-full-modal > div {
  max-width: 100vw;
  max-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cnc-gallery-full-modal__img {
  max-width: 100vw;
  max-height: 100vh;
}

.cnc-gallery-full-modal__img:hover ~ .cnc-gallery-full-modal__close {
  visibility: visible;
}

.cnc-gallery-full-modal__close {
  z-index: 100;
  opacity: .6;
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  transition: all .3s;
  position: absolute;
  top: 20px;
  right: 20px;
}

.cnc-gallery-full-modal__close:hover {
  visibility: visible;
  opacity: 1;
}

.cnc-gallery-slider__img-info {
  text-align: left;
  width: 100%;
  font-size: 14px;
  line-height: 18.2px;
  transition: all .7s;
  position: absolute;
  bottom: 0;
}

.cnc-gallery-slider__img-info > div {
  opacity: .8;
  box-sizing: border-box;
  background-color: #464646;
  width: 100%;
  height: 100%;
  padding: 9px 22px 9px 11px;
}

.cnc-gallery-slider__img-info.cnc-gallery-slider__img-info--closed .cnc-gallery-slider__img-info-show {
  display: block;
}

.cnc-gallery-slider__img-info.cnc-gallery-slider__img-info--closed .cnc-gallery-slider__img-info-close {
  display: none;
}

.cnc-gallery-slider__img-info.cnc-gallery-slider__img-info--closed > div {
  visibility: hidden;
}

.cnc-gallery-slider__img-info-show {
  cursor: pointer;
  display: none;
  position: absolute;
  bottom: 5px;
  right: 8px;
}

.cnc-gallery-slider__img-info-close {
  cursor: pointer;
  width: 20px;
  height: 20px;
  position: absolute;
  bottom: 8px;
  right: 13px;
}

.cnc-gallery-slider__img-info-close:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-slider__img-info-close:before, .cnc-gallery-slider__img-info-close:after {
  content: " ";
  background-color: #fff;
  width: 2px;
  height: 20px;
  display: block;
  position: absolute;
  right: 5px;
}

.cnc-gallery-slider__img-info-close:before {
  transform: rotate(45deg);
}

.cnc-gallery-slider__img-info-close:after {
  transform: rotate(-45deg);
}

.cnc-gallery-slider__img-desc {
  margin-right: 5px;
}

.cnc-gallery-slider__img-auth {
  color: #989898;
  margin-left: 5px;
}

.cnc-gallery-slider__nav {
  opacity: .6;
  cursor: pointer;
  z-index: 1;
  background-color: #fff;
  border-radius: 50%;
  width: 58px;
  height: 58px;
  line-height: 58px;
  position: absolute;
}

.cnc-gallery-slider__nav:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-slider__nav:hover {
  opacity: 1;
}

.cnc-gallery-slider__nav > span {
  border-top: 3px solid #464646;
  border-right: 3px solid #464646;
  width: 14px;
  height: 14px;
  display: inline-block;
  position: absolute;
}

.cnc-gallery-slider__nav--left {
  display: none;
  top: 40%;
  left: 10px;
  transform: translateY(-50%);
}

.cnc-gallery-slider__nav--left > span {
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%)rotate(-135deg);
}

.cnc-gallery-slider__nav--right {
  display: none;
  top: 40%;
  right: 10px;
  transform: translateY(-50%);
}

.cnc-gallery-slider__nav--right > span {
  top: 50%;
  left: 45%;
  transform: translate(-50%, -50%)rotate(45deg);
}

.cnc-gallery-slider__nav--up {
  visibility: hidden;
  opacity: 0;
  transition: visibility, opacity .5s linear;
  position: fixed;
  bottom: 20px;
  right: 10px;
}

.cnc-gallery-slider__nav--up > span {
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%)rotate(-45deg);
}

.cnc-gallery-slider__nav--up:hover {
  opacity: .6;
}

.cnc-gallery-slider__nav-icon {
  pointer-events: none;
}

.cnc-gallery-slider__nav-icon--left, .cnc-gallery-slider__nav-icon--right {
  display: block;
}

.cnc-gallery-slider__nav-icon:focus {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0);
  outline-style: none;
}

.cnc-gallery-slider__ad--mobile {
  text-align: center;
  margin-bottom: 20px;
  display: block;
  overflow: hidden;
}

.cnc-gallery-slider__ad--mobile > div {
  max-width: 100%;
  margin: 0 auto;
}

.cnc-gallery-ad {
  display: none;
}

.cnc-gallery-slider-articles {
  display: block;
}

@media only screen and (min-width: 768px) {
  .cnc-gallery-slider {
    float: left;
    box-sizing: border-box;
    max-width: calc(100% - 360px);
    max-height: calc(100vh - 170px);
    margin-top: 60px;
    margin-left: 15px;
    padding: 0;
    position: relative;
  }

  .cnc-gallery-slider .cnc-gallery-slider__nav {
    display: block;
    top: 48%;
    transform: translate(0%, -50%);
  }

  .cnc-gallery-slider:hover .cnc-gallery-slider__nav--left, .cnc-gallery-slider:hover .cnc-gallery-slider__nav--right {
    visibility: visible;
    display: block;
  }

  .cnc-gallery-slider:hover .cnc-gallery-slider__enlarge-btn {
    visibility: visible;
    z-index: 1;
    background-image: none;
    display: block;
    top: 10px;
    right: 10px;
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized {
    max-height: none;
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__wrap {
    cursor: default;
    background-color: rgba(0, 0, 0, 0);
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrap {
    padding: 5px;
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img-wrapper {
    min-width: 0;
    min-height: 0;
    top: auto;
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__img {
    cursor: pointer;
    width: auto;
    max-width: none;
    height: 550px;
    max-height: none;
    position: absolute;
    top: auto;
    left: 50%;
    transform: translateX(-50%);
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__enlarge-btn {
    visibility: hidden;
    display: none;
  }

  .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__nav--left, .cnc-gallery-slider.cnc-gallery-slider--resized .cnc-gallery-slider__nav--right {
    visibility: hidden;
  }

  .cnc-gallery-centerzone {
    display: none;
  }

  .cnc-gallery-slider__wrap {
    cursor: grab;
    background-color: #000;
    width: 1000000px;
  }

  .cnc-gallery-slider__wrap .cnc-gallery-slider__ad, .cnc-gallery-slider__title, .cnc-gallery-slider__nav.cnc-gallery-slider__nav--up {
    display: none;
  }

  .cnc-gallery-slider__nav--left.disabled, .cnc-gallery-slider__nav--right.disabled {
    cursor: not-allowed;
  }

  .cnc-gallery-slider__nav--left.disabled:hover, .cnc-gallery-slider__nav--right.disabled:hover {
    opacity: 1;
  }

  .cnc-gallery-slider__nav--left.disabled ~ .cnc-gallery-slider__wrap, .cnc-gallery-slider__nav--right.disabled ~ .cnc-gallery-slider__wrap {
    cursor: default;
  }

  .cnc-gallery-slider__img-wrap {
    float: left;
    background-color: rgba(0, 0, 0, 0);
    width: 3000px;
    display: inline-block;
    position: relative;
  }

  .cnc-gallery-slider__img-wrap > div {
    background-color: rgba(0, 0, 0, 0);
    height: 810px;
    max-height: calc(100vh - 170px);
    overflow: hidden;
  }

  .cnc-gallery-slider__img-wrapper {
    width: auto;
    min-width: 350px;
    max-width: 100%;
    height: auto;
    min-height: 200px;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .cnc-gallery-slider__img {
    cursor: auto;
    width: auto;
    height: auto;
    max-height: calc(100vh - 170px);
    display: inline-block;
    position: relative;
    top: auto;
    left: auto;
    transform: none;
  }

  .cnc-gallery-slider__img.lazyload ~ .cnc-gallery-slider__img-info {
    visibility: hidden;
  }

  .cnc-gallery-slider__img.lazyloaded ~ .cnc-gallery-slider__img-info {
    visibility: visible;
  }

  .cnc-gallery-ad {
    overflow: hidden;
  }

  .cnc-gallery-ad--sky {
    float: right;
    text-align: center;
    box-sizing: content-box;
    max-width: 300px;
    height: 100%;
    min-height: 100vh;
    margin: 60px 15px 0 0;
    display: block;
    position: fixed;
    right: 0;
  }

  .cnc-gallery-ad--sky > div {
    max-width: 100%;
    margin: 0 auto;
  }

  .cnc-gallery-ad--bottom {
    float: left;
    box-sizing: border-box;
    text-align: center;
    width: calc(100% - 360px);
    margin-top: 10px;
    margin-left: 15px;
    padding: 0;
    display: block;
    position: relative;
  }

  .cnc-gallery-ad--bottom > div {
    margin: 0 auto;
    display: inline-block;
  }

  .cnc-gallery-slider-articles {
    float: left;
    box-sizing: border-box;
    text-align: center;
    width: calc(100% - 360px);
    margin-top: 100px;
    margin-left: 15px;
    padding: 0;
    display: none;
    position: relative;
  }
}

@media only screen and (min-width: 1200px) {
  .cnc-gallery-ad--sky {
    right: 2.5%;
  }
}

@media only screen and (min-width: 1800px) {
  .cnc-gallery-slider__nav--left {
    left: 10px;
  }

  .cnc-gallery-slider__nav--right {
    right: 10px;
  }
}

.cnc-gallery-articles {
  margin: 20px 0;
  padding: 0 15px;
  font-family: Open Sans, sans-serif;
}

.cnc-gallery-articles__heading {
  margin-bottom: 0;
  font-family: Open Sans, sans-serif;
  font-size: 21px;
  font-weight: normal;
  line-height: 25px;
  display: inline-block;
}

.cnc-gallery-article {
  margin: 20px 0 0;
  display: flex;
}

.cnc-gallery-article__img {
  width: 45%;
  min-height: 98px;
  display: inline-block;
}

.cnc-gallery-article__img img {
  width: 100%;
  height: auto;
}

.cnc-gallery-article__img:hover ~ .cnc-gallery-article__title {
  text-decoration: underline;
}

.cnc-gallery-article__title {
  box-sizing: border-box;
  align-self: center;
  width: 55%;
  padding: 0 0 0 15px;
  font-family: Open Sans, sans-serif;
  font-size: 15px;
  font-weight: normal;
  line-height: 18px;
  transition: all .3s;
  display: block;
}

@media only screen and (min-width: 768px) {
  .cnc-gallery-articles {
    float: left;
    box-sizing: border-box;
    max-width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    overflow: hidden;
  }

  .cnc-gallery-articles__heading {
    display: none;
  }

  .cnc-gallery-articles__wrap {
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 5px 0 0;
    display: flex;
    position: relative;
    overflow: hidden;
  }

  .cnc-gallery-article {
    color: #fff;
    background-color: #1e1e1e;
    width: calc(50% - 20px);
    margin: 0 0 20px;
    display: block;
  }

  .cnc-gallery-article__img {
    min-width: 100%;
    min-height: 100px;
    position: relative;
    overflow: hidden;
  }

  .cnc-gallery-article__img img {
    width: 100%;
    height: auto;
  }

  .cnc-gallery-article__title {
    text-align: left;
    box-sizing: unset;
    width: 100%;
    margin-top: 5px;
    padding: 0;
    font-size: 18px;
    line-height: 25px;
    transition: all .3s;
  }

  .cnc-gallery-article__title:hover a {
    text-decoration: underline;
  }

  .cnc-gallery-article__title a {
    color: inherit;
    text-decoration: none;
  }
}

@media only screen and (min-width: 992px) {
  .cnc-gallery-article {
    width: calc(33.3333% - 30px);
  }
}

.cnc-gallery {
  color: #fff;
  background-color: #1e1e1e;
  width: 100%;
  min-width: 0;
  max-width: 1080px;
  min-height: 99vh;
  margin: 0 auto;
  font-family: Open Sans, sans-serif;
  position: relative;
  overflow: hidden;
}

.cnc-gallery a {
  color: #fff;
  text-decoration: none;
  transition: all .3s;
}

.cnc-gallery a:hover {
  color: inherit;
  text-decoration: underline;
}

.cnc-gallery-html {
  background-color: #1e1e1e;
  height: auto;
  min-height: 100vh;
  overflow-y: scroll;
}

.cnc-gallery-html.full-modal-open {
  overflow-y: hidden;
}

.cnc-gallery-html body {
  background-color: #1e1e1e;
  min-width: 0;
  height: auto;
}

.cnc-gallery-html-body {
  background-color: #1e1e1e;
  width: 100%;
  min-width: 0;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

@media only screen and (min-width: 992px) {
  .cnc-gallery {
    max-width: 100%;
  }
}

@media only screen and (min-width: 1200px) {
  .cnc-gallery {
    max-width: 95%;
  }
}

.cnc-gallery--receptycz .cnc-gallery-header__title, .cnc-gallery--receptycz .cnc-gallery-header__articles-title {
  left: 130px;
}

.cnc-gallery--receptycz .cnc-gallery-header {
  background-color: #fbb03b;
}

.cnc-gallery-html #site-wrapper {
  background-color: #1e1e1e;
}

.breadcrumbs {
  display: none;
}

.cnc-gallery--aha .cnc-gallery-header__title {
  left: 100px;
}

#site-wrapper.gallery {
  padding: 0;
}

.internal-promo {
  border-top: 3px solid red;
  margin: 35px 10px;
  font-weight: bold;
}

.internal-promo h2 {
  text-transform: uppercase;
  color: red;
  margin: 5px 0 10px;
  font-family: tablet_gothic_condensed, sans-serif;
  font-size: 18px;
}

.internal-promo__items:after {
  content: "";
  clear: both;
  width: 100%;
  height: 1px;
  display: block;
}

.internal-promo-items__item {
  float: left;
  width: calc(25% - 15px);
  margin-bottom: 30px;
  margin-right: 20px;
}

.internal-promo-items__item:nth-child(4n+1) {
  clear: both;
}

.internal-promo-items__item:nth-child(4n+4) {
  margin-right: 0;
}

.internal-promo-item__image {
  background: #fff;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 82px;
  margin-bottom: 10px;
  display: flex;
}

.internal-promo-item__image .loading-placeholder {
  justify-content: center;
  align-items: center;
  display: flex;
}

.internal-promo-item__image .loading-placeholder.item-loaded {
  padding-bottom: 0 !important;
}

.internal-promo-item__image .loading-placeholder.item-loaded img {
  object-fit: contain;
  max-width: 140px;
  max-height: 82px;
}

.internal-promo-item__title, .internal-promo-item__more {
  font-family: arial, Helvetica, sans-serif;
  font-size: 15px;
  display: block;
}

.internal-promo-item__title {
  color: #16212d;
  height: 45px;
  margin-bottom: 5px;
  font-size: 13px;
  line-height: 15px;
  text-decoration: none;
  display: block;
  overflow: hidden;
}

.internal-promo-item__title:hover {
  text-decoration: none;
}

.internal-promo-item__more {
  color: red;
  text-transform: lowercase;
  border-bottom: 2px solid red;
  text-decoration: none;
  display: inline-block;
  position: relative;
}

.internal-promo-item__more:hover {
  color: #5c6773;
  border-bottom: 0;
  text-decoration: none;
  transition: all .3s linear;
}

.internal-promo-item__more:before, .internal-promo-item__more:after {
  content: "";
  transform-origin: 100% 100%;
  background-color: red;
  width: 2px;
  height: 5px;
  display: block;
  position: absolute;
  top: 5px;
  right: -10px;
  transform: skewX(40deg);
}

.internal-promo-item__more:after {
  transform-origin: 100% 0;
  top: 10px;
  transform: skewX(-40deg);
}

.internal-promo-item__more:hover:before, .internal-promo-item__more:hover:after {
  background-color: #5c6773;
  transition: all .3s linear;
}

@media only screen and (max-width: 699px) {
  .internal-promo-items__item {
    width: calc(50% - 6px);
    margin-right: 6px;
  }

  .internal-promo-items__item:nth-child(2n+2) {
    margin-right: 0;
  }

  .internal-promo-item__image {
    background: #b6c1cc;
    width: 100%;
  }
}

.newsletter-subscription {
  color: #fff;
  background: url("/images/receptycz/dist/non-sprite/newsletter-subscription-bg.png") 0 0 / cover no-repeat;
  width: 300px;
  min-height: 365px;
  margin: auto;
  padding: 16px;
}

.newsletter-subscription__title {
  color: #fff;
  margin: 0 0 10px;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
}

.newsletter-subscription__field {
  margin-bottom: 15px;
}

.newsletter-subscription__field--checkbox {
  display: flex;
}

.newsletter-subscription__input[type="text"] {
  color: #a9a9a9;
  border: 1px solid #e1e1e1;
  width: 100%;
  height: 48px;
  font-size: 14px;
}

.newsletter-subscription__input[type="checkbox"] {
  width: 0;
  height: 0;
}

.newsletter-subscription__input[type="checkbox"]:before {
  content: "";
  background-color: #fff;
  border: 1px solid #ccc;
  width: 15px;
  height: 15px;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.newsletter-subscription__input[type="checkbox"]:checked:before {
  background-image: url("/images/receptycz/dist/svg/check-mark-icon.svg");
  border: 1px solid #fbb03b;
  width: 15px;
  height: 15px;
}

.newsletter-subscription__label {
  padding-left: 25px;
  font-size: 13px;
  line-height: 15px;
}

.newsletter-subscription__error-message-wrapper {
  position: relative;
}

.newsletter-subscription__error-message {
  color: #ed1d24;
  margin-bottom: 15px;
}

.newsletter-subscription__success-message {
  margin-bottom: 10px;
}

.newsletter-subscription__link {
  color: #fbb03b;
  text-decoration: underline;
}

.newsletter-subscription__link:hover {
  text-decoration: none;
}

.newsletter-subscription__button {
  color: #fff;
  background-color: #ed1d24;
  border: none;
  width: 100%;
  height: 48px;
  font-size: 14px;
  line-height: 16px;
}

.newsletter-subscription__recaptcha-text {
  font-size: 10px;
  line-height: 11px;
}

